import React from "react";
import PolarisUtils from "utils/PolarisUtils";

export default class Constants {
    static MAX_DB_STRING_LENGTH = 399000;

    static PAGINATION_LIMIT = 50;

    static SERVICE_LIMIT = 1000;

    static DATE_FORMAT = "YYYY/MM/DD";

    // TODO Get rid of this in favor of Box components (rather than divs with this class)
    static FREMONT_PAGE_WIDTH_CLASS = "fremont-page-width";

    static FREMONT = "fremont";

    static MIMICKED_USER = "mimickedUser";

    static LOADING = "loading";

    static FINISHED = "finished";

    static PENDING = "pending";

    static OTHER = "Other";

    static PROMISE_FULFILLED = "fulfilled";

    static PROMISE_REJECTED = "rejected";

    static FIRST_INDEX_INDICATOR = "1";

    static UNKNOWN_DATE = "Unknown";

    static ALL_CIRCUITS = "allCircuits";

    static ESCALATION_PATH_LEVELS = [1, 2, 3, 4, 5, 6];

    static PROVIDER_AUTOCOMPLETE_PLACEHOLDER_TEXT = "Enter Provider Name Here";

    static NO_BREAK_SPACE_UNICODE = `\u00A0`;

    static SEPARATOR = "_";

    static UUID_SEPARATOR = "-";

    static POUND_SEPARATOR = "#";

    static BLANK_OPTION = "None";

    static SPINNER_ORANGE_COLOR ="#ec7211";

    static FREMONT_DATA_SOURCE = "Fremont";

    static AMAZON = "Amazon";

    static PROVIDER = "Provider";

    static NUMBER = "number";

    static TEXT = "text";

    static TRUE_STRING = "true";

    static FALSE_STRING = "false";

    static ADD_CIRCUIT_TO_ORDER = "addCircuitToOrder";

    static REMOVE_CIRCUIT_FROM_ORDER = "removeCircuitFromOrder";

    static CIRCUIT_DELIMETER = "CIRCUIT-";

    static ORDER_DELIMETER = "ORDER-";

    static INCOMPLETE = "Incomplete";

    static COMPLETE = "Complete";

    static REJECTION_REASON = "Rejection Reason:";

    static SPAN_DECOM_REASON = "Span Decom Reason:";

    static ORDER_ENTITY_TYPE = "Order";

    static PROVIDER_SERVICE_ENTITY_TYPE = "ProviderService";

    static CIRCUIT_DESIGN_ENTITY_TYPE = "CircuitDesign";

    static BULK_UPDATE_JOB_ENTITY_TYPE = "Job";

    static SELECT_FILTERING_NO_MATCH_MESSAGE = "No matches for that search.";

    static CONTRACT_CENTRAL_URL = "https://contractcentral.amazon.com/contract/";

    static LOCAL_HOST_URL = "localhost:8080";

    static SUBMIT_BUTTON_ID = "buttonSubmitId";

    static INTERNAL_AMAZON_PROVIDER = "Amazon Internal (Optical Team)";

    static NON_INTERNAL_AMAZON_PROVIDER = "Not Amazon Internal";
    // this provider will be used for optical UAT until we switch to INTERNAL_AMAZON_PROVIDER
    static BACKBONE_OPTICAL_UAT_PROVIDER = "Backbone Optical UAT"

    // https://stackoverflow.com/questions/68600535/how-to-include-ascii-code-symbol-in-react
    static RIGHT_ARROW_UNICODE = "\u2192";

    static HAS_NOT_BEEN_SUBMITTED_FOR_APPROVAL = "Circuit(s) missing field(s). Order has not been submitted for "
        + "approval.";

    static HAS_NOT_BEEN_SUBMITTED_FOR_APPROVAL_OR_APPROVED = "Circuit(s) missing field(s). Order has not been "
        + "submitted for approval. Order has not been approved.";

    static CIRCUIT_HAS_FUTURE_ACTIVE_REVISIONS = "At least one circuit in this order has been revised.";

    static CIRCUIT_HAS_NO_FUTURE_ACTIVE_REVISIONS = "This order contains the latest active revisions for"
        + " all circuits on this order.";

    static HAS_NOT_BEEN_APPROVED = "Circuit(s) missing field(s). Order has not been approved.";

    static CIRCUITS_MISSING_FIELDS = "Circuit(s) missing field(s).";

    static ORDER_MISSING_FIELDS = "Order missing field(s).";

    static LATE_SERVICE_DELIVERY_CREDIT_REASON_INFO = "Services delivered 10 or more days are reviewed by Biz Ops. " +
        "Please provide a brief explanation in the 'Order Notes' tab, of why the service does, or does not," +
        " qualify for late service delivery credit.";

    static NCIS_DATASOURCE = "NCIS";

    static MAX_ATTACHMENT_SIZE = 50000000;

    static NCIS_DUMMY_COMPONENT_VALUE = "_NA";

    static TEMPLATE_GENERATOR_LINK = "https://w.amazon.com/index.php/Networking/Deployment/Capacity_Engineering/Cabling_Request_Links?isdtp=p1#HDCO26SiteInformation";

    static API_VERSION_V2 = "v2";

    // https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/RequestAndResponseBehaviorCustomOrigin.html#RequestCustomMaxRequestStringLength
    static CLOUDFRONT_URL_LIMIT = 8000;

    static AUDIT_CHANGED_VALUE_PREFIX_TO_IGNORE = "[FREMONT INTERNAL AUDIT]";

    static YYYYDDMM_HHMMSS_FORMAT = "YYYY-MM-DD HH:mm:ss z";
    static SERVER_FORMAT = "YYYY-MM-DDThh:mm:ss.SSSZZ";
    static DATE_PICKER_FORMAT = "YYYY-MM-DD";
    static VALID_PARSING_FORMATS = [
        Constants.SERVER_FORMAT
    ];

    static PENDING_DECOMMISSION = "PENDING_DECOMMISSION";
    static DECOMMISSIONED = "DECOMMISSIONED";
    static IGNORED_CINNAMON_PATH_LIFECYCLES = [
        Constants.PENDING_DECOMMISSION,
        Constants.DECOMMISSIONED
    ];

    static NCIS_ONLY_PROVIDERS = ["NA", "Amazon AWS Internal", "Amazon Corporate"];

    static BATCH_ENTITIES = {
        ASN: "asn",
        ATTACHMENT: "attachment",
        AUDIT: "audit",
        BILLING_SEGMENT: "billingSegment",
        BLOCKER: "blocker",
        CUSTOM_ATTRIBUTE: "customAttribute",
        CIRCUIT_DESIGN: "circuitDesign",
        CONTACT: "contact",
        DEMARC_AND_CFA: "demarcAndCfa",
        LAG: "lag",
        NODE: "node",
        NOTE: "note",
        ORDER: "order",
        PATH: "path",
        PORT: "port",
        PROVIDER: "provider",
        PROVIDER_CIRCUIT: "providerCircuit",
        PROVIDER_SERVICE: "providerService",
        RESOURCE: "resource",
        SITE: "site",
        SPAN: "span",
        STAGESLA: "stageSla",
        UNIT: "unit",
        WORK_ORDER: "workOrder"
    };

    static ID_AND_DISPLAY_VALUE = {
        DISPLAY_VALUE: "displayValue",
        ID: "id"
    };

    static ORDER_TYPES = {
        INSTALL: "INSTALL",
        CHANGE: "CHANGE",
        CTC: "CTC",
        DECOMMISSION: "DECOMMISSION",
        FABRIC_MIGRATION: "FABRIC MIGRATION",
        RENEWAL: "RENEWAL",
        RERATE: "RERATE"
    }

    static BILLING_ORDER_TYPES = [
        Constants.ORDER_TYPES.CTC,
        Constants.ORDER_TYPES.RENEWAL,
        Constants.ORDER_TYPES.RERATE
    ];

    static ORDER_TAB_IDS = {
        ATTACHMENTS_TAB_ID: "attachments",
        AUDIT_TAB_ID: "audits",
        BUSINESS_DEVELOPER_TAB_ID: "businessDeveloper",
        BUSINESS_OPERATIONS_TAB_ID: "businessOperations",
        CAPACITY_ENGINEERING_TAB_ID: "capacityEngineering",
        CAPACITY_PROVISIONING_TAB_ID: "capacityProvisioning",
        CHANGE_ORDER_CREATOR_ID: "changeOrderCreator",
        CIRCUIT_DESIGN_TAB_ID: "circuitDesigns",
        INFO_TAB_ID: "details",
        NOTE_TAB_ID: "notes",
        OPTICAL_BACKBONE_TAB_ID: "opticalBackbone"
    };

    static POSIX_GROUPS = {
        FREMONT_AWS_BACKBONE_OPTICAL: "fremont-aws-backbone-optical",
        FREMONT_AWS_BBONE_CAPENG: "fremont-aws-bbone-capeng",
        FREMONT_AWS_BD: "fremont-aws-bd",
        FREMONT_AWS_BIZOPS: "fremont-aws-bizops",
        FREMONT_AWS_FINANCE: "fremont-aws-finance",
        FREMONT_AWS_IP_CAPENG: "fremont-aws-ip-capeng",
        FREMONT_AWS_MANAGEMENT: "fremont-aws-management",
        FREMONT_AWS_PROVISIONERS: "fremont-aws-provisioners",
        FREMONT_AWS_REPORTING_AUTHOR: "fremont-aws-reporting-author",
        FREMONT_AWS_REPORTING_READER: "fremont-aws-reporting-reader",
        FREMONT_AWS_HIGHLY_CONFIDENTIAL: "fremont-aws-highly-confidential",
        FREMONT_UPDATE_ORDER_PRIORITY: "fremont-update-order-priority",
        NEST: "nest"
    };

    static TAB_TO_POSIX_GROUP_MAP = {
        [Constants.ORDER_TAB_IDS.BUSINESS_DEVELOPER_TAB_ID]: [Constants.POSIX_GROUPS.FREMONT_AWS_BD],
        [Constants.ORDER_TAB_IDS.BUSINESS_OPERATIONS_TAB_ID]: [Constants.POSIX_GROUPS.FREMONT_AWS_BIZOPS],
        [Constants.ORDER_TAB_IDS.CAPACITY_PROVISIONING_TAB_ID]: [Constants.POSIX_GROUPS.FREMONT_AWS_PROVISIONERS],
        [Constants.ORDER_TAB_IDS.CAPACITY_ENGINEERING_TAB_ID]: [Constants.POSIX_GROUPS.FREMONT_AWS_IP_CAPENG,
            Constants.POSIX_GROUPS.FREMONT_AWS_BBONE_CAPENG],
        [Constants.ORDER_TAB_IDS.CHANGE_ORDER_CREATOR_ID]: [Constants.POSIX_GROUPS.FREMONT_AWS_BD,
            Constants.POSIX_GROUPS.FREMONT_AWS_BIZOPS, Constants.POSIX_GROUPS.FREMONT_AWS_PROVISIONERS,
            Constants.POSIX_GROUPS.FREMONT_AWS_IP_CAPENG, Constants.POSIX_GROUPS.FREMONT_AWS_BBONE_CAPENG
        ],
        [Constants.ORDER_TAB_IDS.OPTICAL_BACKBONE_TAB_ID]: [Constants.POSIX_GROUPS.FREMONT_AWS_BACKBONE_OPTICAL]
    }

    static POSIX_GROUP_TO_TAB_MAP = {
        [Constants.POSIX_GROUPS.FREMONT_AWS_BBONE_CAPENG]: [Constants.ORDER_TAB_IDS.CAPACITY_ENGINEERING_TAB_ID,
            Constants.ORDER_TAB_IDS.CHANGE_ORDER_CREATOR_ID],
        [Constants.POSIX_GROUPS.FREMONT_AWS_BD]: [Constants.ORDER_TAB_IDS.BUSINESS_DEVELOPER_TAB_ID,
            Constants.ORDER_TAB_IDS.CHANGE_ORDER_CREATOR_ID],
        [Constants.POSIX_GROUPS.FREMONT_AWS_BIZOPS]: [Constants.ORDER_TAB_IDS.BUSINESS_OPERATIONS_TAB_ID,
            Constants.ORDER_TAB_IDS.CHANGE_ORDER_CREATOR_ID],
        [Constants.POSIX_GROUPS.FREMONT_AWS_FINANCE]: [],
        [Constants.POSIX_GROUPS.FREMONT_AWS_IP_CAPENG]: [Constants.ORDER_TAB_IDS.CAPACITY_ENGINEERING_TAB_ID,
            Constants.ORDER_TAB_IDS.CHANGE_ORDER_CREATOR_ID],
        [Constants.POSIX_GROUPS.FREMONT_AWS_MANAGEMENT]: [],
        [Constants.POSIX_GROUPS.FREMONT_AWS_PROVISIONERS]: [Constants.ORDER_TAB_IDS.CAPACITY_PROVISIONING_TAB_ID,
            Constants.ORDER_TAB_IDS.CHANGE_ORDER_CREATOR_ID],
        [Constants.POSIX_GROUPS.FREMONT_AWS_REPORTING_AUTHOR]: [],
        [Constants.POSIX_GROUPS.FREMONT_AWS_REPORTING_READER]: [],
        [Constants.POSIX_GROUPS.NEST]: []
    };

    static NOTE_ENTITY_TYPES = {
        order: "order",
        blocker: "blocker"
    }

    static SPINNER_SIZES = {
        loadingPage: 100,
        blockerModal: 50
    };

    static COLUMN_WIDTHS = {
        bandwidth: 115,
        checkbox: 50,
        circuitDesignIdColumn: 135,
        statusColumn: 110,
        siteColumn: 90,
        completeColumn: 160,
        version: 95
    };

    static MINIMUM_COLUMN_WIDTHS = {
        editableColumn: 240
    };

    static LOA_DISPOSITIONS = {
        amazon: "Amazon to Provide",
        provider: "Provider to Provide"
    };

    static TABLE_IDS = {
        circuitDesignLink: "circuitDesignLink",
        consumedByPathCircuit: "consumedByPathCircuit",
        groupingId: "groupingId",
        providerLink: "providerLink",
        siteA: "siteA",
        siteZ: "siteZ",
        stageStatus: "stageStatus",
        circuitActivationStatus: "circuitActivationStatus"
    };

    static IP_TESTING_ATTRIBUTES = {
        ipTestingStatus: "ipTestingStatus",
        amazonTestIPv4: "amazonTestIPv4",
        providerTestIPv4: "providerTestIPv4",
        amazonTestIPv6: "amazonTestIPv6",
        providerTestIPv6: "providerTestIPv6",
        vlanNumber: "vlanNumber",
        notStarted: "Not Started",
        notRequired: "Not Required",
        inProgress: "In Progress",
        completed: "Completed",
        entityType: "entityType",
        entityId: "entityId",
        entityIdEntityType: "entityIdEntityType"
    };

    static IP_TESTING_STATUSES = [
        Constants.IP_TESTING_ATTRIBUTES.notStarted,
        Constants.IP_TESTING_ATTRIBUTES.notRequired,
        Constants.IP_TESTING_ATTRIBUTES.inProgress,
        Constants.IP_TESTING_ATTRIBUTES.completed
    ];

    static EMPTY_TABLE_MESSAGES = {
        noCircuits: "No circuits currently exist for this order.",
        noBlockers: "No blockers currently exist for this order.",
        noSpans: "No spans currently exist for the search parameters",
        noBillings: "No billing segments currently exist for the search parameters",
        noCircuitsFromQuery: "No circuits exist for the search parameters.",
        noCircuitsAddedToOrder: "No circuits have been added to this order yet.",
        noCircuitAttachments: "No attachments currently exist for this circuit.",
        noOrderAttachments: "No attachments currently exist for this order.",
        noProviderServiceAttachments: "No attachments currently exist for this provider service.",
        noUnits: "Need to add port/lag information before adding unit information.",
        noResources: "No resources exist.",
        noTopology: "No topology exists for this order.",
        noPorts: "Need to add port information before adding port IP testing information.",
        noLags: "Need to add lag information before adding lag IP testing information.",
        noStageSlas: "No stage SLAs exist.",
        noOpticalTopology: "No optical design string exists for this order.",
        opticalTopologyAlreadyAdded: "Optical topology already added for this order."
    };

    static BACKEND_TO_FRONTEND_MAP = {
        [Constants.ORDER_TYPES.INSTALL]: "Install",
        [Constants.ORDER_TYPES.CHANGE]: "Change",
        [Constants.ORDER_TYPES.CTC]: "Commercial Terms Correction",
        [Constants.ORDER_TYPES.DECOMMISSION]: "Decommission",
        [Constants.ORDER_TYPES.FABRIC_MIGRATION]: "Fabric Migration",
        [Constants.ORDER_TYPES.RENEWAL]: "Renewal",
        [Constants.ORDER_TYPES.RERATE]: "Re-rate"
    }

    static ATTRIBUTES = {
        actualDeliveryDate: "actualDeliveryDate",
        actualFiberDeliveryDate: "actualFiberDeliveryDate",
        address: "address",
        assignmentId: "assignmentId",
        assignmentIdForNewRevision: "assignmentIdForNewRevision",
        assignmentType: "assignmentType",
        amazonIPv4: "amazonIPv4",
        amazonIPv6: "amazonIPv6",
        approvalUrl: "approvalUrl",
        asnId: "asnId",
        asnIdList: "asnIdList",
        asnNumber: "asnNumber",
        atpFile: "atpFile",
        attachmentId: "attachmentId",
        awsRegion: "awsRegion",
        BERTAttachmentId: "BERTAttachmentId",
        billingSegmentId: "billingSegmentId",
        billingSegmnetIdList: "billingSegmentIdList",
        billingEndDate: "billingEndDate",
        billingStartDate: "billingStartDate",
        blockerId: "blockerId",
        bgpIPv4MD5Key: "bgpIPv4MD5Key",
        bgpIPv6MD5Key: "bgpIPv6MD5Key",
        buildingOwner: "buildingOwner",
        businessDeveloper: "businessDeveloper",
        businessNeed: "businessNeed",
        businessOperationsId: "businessOperationsId",
        cablingRemovalStatus: "cablingRemovalStatus",
        canonicalPathId: "canonicalPathId",
        carrierConfirmationStatus: "carrierConfirmationStatus",
        carrierNotificationStatus: "carrierNotificationStatus",
        carrierBuildCompleted: "carrierBuildCompleted",
        cfa: "CFA",
        changedValues: "changedValues",
        changeSet: "changeSet",
        changeSetAmazonIPv4: "changeSetAmazonIPv4",
        changeSetAmazonIPv6: "changeSetAmazonIPv6",
        changeSetApprovalUrl: "changeSetApprovalUrl",
        changeSetBgpIPv4MD5Key: "changeSetBgpIPv4MD5Key",
        changeSetBgpIPv6MD5Key: "changeSetBgpIPv6MD5Key",
        changeSetCurrency: "changeSetCurrency",
        changeSetExpectedDeliveryDate: "changeSetExpectedDeliveryDate",
        changeSetMrc: "changeSetMrc",
        changeSetNrc: "changeSetNrc",
        changeSetOrderId: "changeSetOrderId",
        changeSetProviderIPv4: "changeSetProviderIPv4",
        changeSetProviderIPv6: "changeSetProviderIPv6",
        changeSetSpanServiceType: "changeSetSpanServiceType",
        changeSetTaxUrl: "changeSetTaxUrl",
        changeSetTerm: "changeSetTerm",
        childProviderNameList: "childProviderNameList",
        circuitActivationStatus: "circuitActivationStatus",
        circuitBandwidth: "circuitBandwidth",
        circuitDesignId: "circuitDesignId",
        circuitDesignIdFromRequest: "circuitDesignIdFromRequest",
        circuitDesignIdList: "circuitDesignIdList",
        circuitDesignIdListFromRequest: "circuitDesignIdListFromRequest",
        circuitDesignNumber: "circuitDesignNumber",
        circuitQuantity: "circuitQuantity",
        clientCutsheetMcm: "clientCutsheetMcm",
        clliCode: "clliCode",
        cluster: "cluster",
        colocationOwner: "colocationOwner",
        coloSiteName: "coloSiteName",
        completedDate: "completedDate",
        completionNoticeId: "completionNoticeId",
        componentNameFromRequest: "componentNameFromRequest",
        connectorType: "connectorType",
        consumableForType: "consumableForType",
        consumedByCircuitId: "consumedByCircuitId",
        consumedByCircuitIdForNewRevision: "consumedByCircuitIdForNewRevision",
        consumingCircuitsIdList: "consumingCircuitsIdList",
        comprehendDropdown: "comprehendDropdown",
        costOutStatus: "costOutStatus",
        contractCentralUrl: "contractCentralUrl",
        contractCentralUrlToDisplay: "contractCentralUrlToDisplay",
        contactId: "contactId",
        contactIdList: "contactIdList",
        countriesSupported: "countriesSupported",
        createdBy: "createdBy",
        createdTime: "createdTime",
        createSpanDecomOrdersOnTrigger: "createSpanDecomOrdersOnTrigger",
        currency: "currency",
        customAttributeId: "customAttributeId",
        customAttributeValue: "customAttributeValue",
        customerFabric: "customerFabric",
        dashboardName: "dashboardName",
        dashboardId: "dashboardId",
        dataSource: "dataSource",
        decommissioningMcm: "decommissioningMcm",
        defaultResource: "defaultResource",
        demarcAndCfa: "demarcAndCfa",
        demarcAndCfaId: "demarcAndCfaId",
        description: "description",
        deviceName: "deviceName",
        deviceNameInterfaceName: "deviceNameInterfaceName",
        email: "email",
        encryptionType: "encryptionType",
        engineerId: "engineerId",
        entityIdList: "entityIdList",
        entityType: "entityType",
        estimatedCarrierBuildCompletionDate: "estimatedCarrierBuildCompletionDate",
        estimatedFiberDeliveryDate: "estimatedFiberDeliveryDate",
        existingLagCircuitCount: "existingLagCircuitCount",
        existingLagQuantity: "existingLagQuantity",
        expectedDeliveryDate: "expectedDeliveryDate",
        expirationDate: "expirationDate",
        fax: "fax",
        fbn: "fbn",
        fiberHandoverMcm: "fiberHandoverMcm",
        fiberType: "fiberType",
        fileName: "fileName",
        filter: "filter",
        filterEnd: "filterEnd",
        filterStart: "filterStart",
        filterStatus: "filterStatus",
        firstName: "firstName",
        futureCircuitDesignRevisionsIdList: "futureCircuitDesignRevisionsIdList",
        geographicRegion: "geographicRegion",
        hasBeenSubmittedForApproval: "hasBeenSubmittedForApproval",
        hasBeenApproved: "hasBeenApproved",
        ilaSiteSurveyCompletionDate: "ilaSiteSurveyCompletionDate",
        industry: "industry",
        installAndDecommission: "installAndDecommission",
        interfaceName: "interfaceName",
        implementationStatus: "implementationStatus",
        ipDisposition: "ipDisposition",
        isDecommissioningMcmExecuted: "isDecommissioningMcmExecuted",
        isFiberDelivered: "isFiberDelivered",
        isHardwareDelivered: "isHardwareDelivered",
        isILASiteSurveyComplete: "isILASiteSurveyComplete",
        isMutualTestComplete: "isMutualTestComplete",
        isOpticalClientCutsheetSharedForCabling: "isOpticalClientCutsheetSharedForCabling",
        isOrderCompletedFromRequest: "isOrderCompletedFromRequest",
        isOrderCancelledFromRequest: "isOrderCancelledFromRequest",
        isProtected: "isProtected",
        isSystemCommissioningComplete: "isSystemCommissioningComplete",
        isTerminalFOCComplete: "isTerminalFOCComplete",
        jepCode: "jepCode",
        jobs: "jobs",
        jobId: "jobId",
        jobStatus: "jobStatus",
        jobType: "jobType",
        kmzAttachmentId: "kmzAttachmentId",
        lacpProvider: "lacpProvider",
        lagId: "lagId",
        lastName: "lastName",
        lateServiceDeliveryCreditReason: "lateServiceDeliveryCreditReason",
        lhDesignSIM: "lhDesignSIM",
        lifeCycleStage: "lifeCycleStage",
        lightLevelTestingStatus: "lightLevelTestingStatus",
        loaAttachmentIdA: "loaAttachmentIdA",
        loaAttachmentIdZ: "loaAttachmentIdZ",
        lineCutsheetMcm: "lineCutsheetMcm",
        linkHandoff: "linkHandoff",
        loaDisposition: "loaDisposition",
        managedSite: "managedSite",
        market: "market",
        meta: "meta",
        maxLatencyProtectState: "maxLatencyProtectState",
        maxLatencySteadyState: "maxLatencySteadyState",
        mgmtCutsheetMcm: "mgmtCutsheetMcm",
        modifiedBy: "modifiedBy",
        modifiedTime: "modifiedTime",
        mrc: "mrc",
        mutualTestCompletionDate: "mutualTestCompletionDate",
        netNewLagCircuitCount: "netNewLagCircuitCount",
        netNewLagQuantity: "netNewLagQuantity",
        newNote: "newNote",
        noteIdList: "noteIdList",
        nodeId: "nodeId",
        nodeIdFromRequest: "nodeIdFromRequest",
        nrc: "nrc",
        objectStatusColumnSearchable: "objectStatusColumnSearchable",
        opticalDesignString: "opticalDesignString",
        opsChecklistMcm: "opsChecklistMcm",
        opticalClientCutsheetMcm: "opticalClientCutsheetMcm",
        opticalCutsheetMap: "opticalCutsheetMap",
        opticalEngineerId: "opticalEngineerId",
        congoRequired: "congoRequired",
        orderId: "orderId",
        orderIdFromRequest: "orderIdFromRequest",
        orderIdListToAddFromRequest: "orderIdListToAddFromRequest",
        orderIdListToRemoveFromRequest: "orderIdListToRemoveFromRequest",
        orderType: "orderType",
        orderSentToSupplier: "orderSentToSupplier",
        originalCustomerFabric: "originalCustomerFabric",
        orderVariant: "orderVariant",
        orderEta: "orderEta",
        pathId: "pathId",
        pathName: "pathName",
        ownerId: "ownerId",
        parentProviderName: "parentProviderName",
        peeringDBInternetExchangeName: "peeringDBInternetExchangeName",
        phone: "phone",
        portBandwidth: "portBandwidth",
        portId: "portId",
        portIdFromRequest: "portIdFromRequest",
        portSize: "portSize",
        positionMap: "positionMap",
        previousCircuitDesignRevisionsIdList: "previousCircuitDesignRevisionsIdList",
        priorityType: "priorityType",
        projectType: "projectType",
        provider: "provider",
        providerIPv4: "providerIPv4",
        providerIPv6: "providerIPv6",
        providerCircuitId: "providerCircuitId",
        providerCircuitName: "providerCircuitName",
        providerCircuitNameForNewRevision: "providerCircuitNameForNewRevision",
        providerName: "providerName",
        providerNameList: "providerNameList",
        providerServiceIdList: "providerServiceIdList",
        provisionerId: "provisionerId",
        quarterlySpend: "quarterlySpend",
        rackCutsheetMcm: "rackCutsheetMcm",
        receiveLightLevelA: "receiveLightLevelA",
        receiveLightLevelZ: "receiveLightLevelZ",
        region: "region",
        regionalDefault: "regionalDefault",
        regionsSupported: "regionsSupported",
        relatedOrderId: "relatedOrderId",
        relatedOrderIdList: "relatedOrderIdList",
        relatedOrderStatusList: "relatedOrderStatusList",
        releasePortStatus: "releasePortStatus",
        removeCircuitDesignIdFromRequest: "removeCircuitDesignIdFromRequest",
        requiredCompletionDate: "requiredCompletionDate",
        requiredFieldsCompletedMap: "requiredFieldsCompletedMap",
        resourceId: "resourceId",
        resourceName: "resourceName",
        resourceType: "resourceType",
        responsibleContactId: "responsibleContactId",
        revisionNumber: "revisionNumber",
        RFCAttachmentId: "RFCAttachmentId",
        rfcTestingLatency: "rfcTestingLatency",
        riverMineBillingCircuitId: "riverMineBillingCircuitId",
        serviceType: "serviceType",
        siteAId: "siteAId",
        siteBId: "siteBId",
        siteBIdFromRequest: "siteBIdFromRequest",
        siteId: "siteId",
        siteMap: "siteMap",
        siteName: "siteName",
        siteType: "siteType",
        siteYId: "siteYId",
        siteYIdFromRequest: "siteYIdFromRequest",
        siteZId: "siteZId",
        spanChangeSet: "spanChangeSet",
        spanId: "spanId",
        spanName: "spanName",
        spanNumber: "spanNumber",
        spanServiceType: "spanServiceType",
        spanType: "spanType",
        stageName: "stageName",
        stageStatusMap: "stageStatusMap",
        stageMap: "stageMap",
        stageSlaId: "stageSlaId",
        startDate: "startDate",
        status: "status",
        subStatusMap: "subStatusMap",
        systemCommissioningCompletionDate: "systemCommissioningCompletionDate",
        systemHandoverMcm: "systemHandoverMcm",
        tabName: "tabName",
        tableName: "tableName",
        topologyCustomerFabric: "topologyCustomerFabric",
        topologyMap: "topologyMap",
        tagId: "tagId",
        tagName: "tagName",
        term: "term",
        taxUrl: "taxUrl",
        terminalFOCCompletionDate: "terminalFOCCompletionDate",
        transitType: "transitType",
        transientFields: "transientFields",
        transmitLightLevelA: "transmitLightLevelA",
        transmitLightLevelZ: "transmitLightLevelZ",
        transportRegion: "transportRegion",
        transportCorridor: "transportCorridor",
        ttMap: "ttMap",
        ttUrl: "ttUrl",
        turnDownStatus: "turnDownStatus",
        unitId: "unitId",
        usingExistingLag: "usingExistingLag",
        verifyAttachmentFromRequest: "verifyAttachmentFromRequest",
        verifyStopBillingStatus: "verifyStopBillingStatus",
        workOrder: "workOrder",
        workOrderChangeSet: "workOrderChangeSet",
        workOrderId: "workOrderId",
        website: "website"
    };

    static ATTRIBUTE_LABELS = {
        [Constants.ATTRIBUTES.providerCircuitName]: "Provider Circuit ID",
        [Constants.ATTRIBUTES.maxLatencyProtectState]: "Max Latency Protect State:",
        [Constants.ATTRIBUTES.maxLatencySteadyState]: "Max Latency Steady State:",
        [Constants.ATTRIBUTES.isProtected]: "Is Protected:",
        [Constants.ATTRIBUTES.spanType]: "Span Type:",
        [Constants.ATTRIBUTES.rfcTestingLatency]: "RFC Testing Latency:"
    };

    /**
     * Generated using generateAllowList.py in FremontDataModel
     * Used for keysToKeep in HelperFunctions.createV2Objects and for general use when modifying Fremont objects
     */
    static FREMONT_OBJECTS = {
        billingSegment: {
            billingSegmentId: "billingSegmentId",
            riverMineBillingCircuitId: "riverMineBillingCircuitId",
            billingStartDate: "billingStartDate",
            billingEndDate: "billingEndDate"
        },
        customAttribute: {
            customAttributeId: "customAttributeId",
            entityIdEntityType: "entityIdEntityType",
            entityType: "entityType",
            entityId: "entityId",
            customAttributeValue: "customAttributeValue"
        },
        demarcAndCfa: {
            // demarcAndCfaId: "demarcAndCfaId", // Not allowed in V2 (yet)
            // siteId: "siteId", // Not allowed in V2
            // circuitDesignId: "circuitDesignId", // Not allowed to be modified directly
            circuitDesignIdFromRequest: "circuitDesignIdFromRequest",
            // circuitDesignIdForNewRevision: "circuitDesignIdForNewRevision", // Not allowed to be modified directly
            assignmentType: "assignmentType",
            assignmentId: "assignmentId",
            // assignmentIdForNewRevision: "assignmentIdForNewRevision", // Not allowed to be modified directly
            componentNameFromRequest: "componentNameFromRequest",
            removeCircuitDesignIdFromRequest: "removeCircuitDesignIdFromRequest",
            // auditIdList: "auditIdList", // Should not be passed in
            // createdBy: "createdBy", // Should not be passed in
            // modifiedBy: "modifiedBy", // Should not be passed in
            // createdTime: "createdTime", // Should not be passed in
            // modifiedTime: "modifiedTime", // Should not be passed in
            meta: "meta"
        },
        order: {
            orderId: "orderId",
            // workflow: "workflow", Shouldn't be passed in
            providerName: "providerName",
            asnId: "asnId",
            orderType: "orderType",
            serviceType: "serviceType",
            customerFabric: "customerFabric",
            portSize: "portSize",
            circuitQuantityToAddFromRequest: "circuitQuantityToAddFromRequest",
            // circuitDesignIdList: "circuitDesignIdList", * DEPRACTED IN V2 *
            circuitDesignIdListToAddFromRequest: "circuitDesignIdListToAddFromRequest",
            circuitDesignIdListToRemoveFromRequest: "circuitDesignIdListToRemoveFromRequest",
            // requiredFieldsCompletedMap: "requiredFieldsCompletedMap", Should not be passed in
            // stageStatusMap: "stageStatusMap", Should not be passed in
            // providerServiceId: "providerServiceId", it is inferred from serviceType and provider on backend
            contactId: "contactId",
            businessNeed: "businessNeed",
            engineerId: "engineerId",
            provisionerId: "provisionerId",
            businessOperationsId: "businessOperationsId",
            requiredCompletionDate: "requiredCompletionDate",
            ownerId: "ownerId",
            loaDisposition: "loaDisposition",
            loaCircuitsQuantityAmazon: "loaCircuitsQuantityAmazon",
            loaCircuitsQuantityProvider: "loaCircuitsQuantityProvider",
            ipDisposition: "ipDisposition",
            // blockerIdList: "blockerIdList", Should not be passed in
            circuitHandoff: "circuitHandoff",
            providerSideHardwareDiversity: "providerSideHardwareDiversity",
            siteAId: "siteAId",
            siteZId: "siteZId",
            lacpProvider: "lacpProvider",
            ttUrl: "ttUrl",
            linkHandoff: "linkHandoff",
            spanId: "spanId",
            netNewLagQuantity: "netNewLagQuantity",
            netNewLagCircuitCount: "netNewLagCircuitCount",
            existingLagQuantity: "existingLagQuantity",
            existingLagCircuitCount: "existingLagCircuitCount",
            description: "description",
            approvalUrl: "approvalUrl",
            taxUrl: "taxUrl",
            orderRegion: "orderRegion",
            orderSentToSupplier: "orderSentToSupplier",
            estimatedCarrierBuildCompletionDate: "estimatedCarrierBuildCompletionDate",
            carrierBuildCompleted: "carrierBuildCompleted",
            transitType: "transitType",
            peeringDBInternetExchangeName: "peeringDBInternetExchangeName",
            priorityType: "priorityType",
            isOrderCompletedFromRequest: "isOrderCompletedFromRequest",
            clientFromRequest: "clientFromRequest",
            hasBeenSubmittedForApproval: "hasBeenSubmittedForApproval",
            createSpanDecomOrdersOnTrigger: "createSpanDecomOrdersOnTrigger",

            hasOpticalTopologyBeenCompleted: "hasOpticalTopologyBeenCompleted",
            hasTopologyBeenCompleted: "hasTopologyBeenCompleted",
            hasTroubleshootingBeenCompleted: "hasTroubleshootingBeenCompleted",
            hasCablingBeenCompleted: "hasCablingBeenCompleted",
            hasIpAllocationBeenCompleted: "hasIpAllocationBeenCompleted",
            hasPortReservationBeenCompleted: "hasPortReservationBeenCompleted",

            connectorType: "connectorType",
            fiberType: "fiberType",
            lhDesignSIM: "lhDesignSIM",

            hasBeenApproved: "hasBeenApproved",
            blockedStages: "blockedStages",
            installAndDecommission: "installAndDecommission",
            relatedOrderIdList: "relatedOrderIdList",
            // auditIdList: "auditIdList", Should not be passed in
            // noteIdList: "noteIdList", Should not be passed in
            // tagIdList: "tagIdList",Should not be passed in
            attachmentIdMap: "attachmentIdMap",
            // stageTimerListMap: "stageTimerListMap", Should not be passed in
            // createdBy: "createdBy", Should not be passed in
            // modifiedBy: "modifiedBy", Should not be passed in
            // createdTime: "createdTime", Should not be passed in
            // modifiedTime: "modifiedTime", Should not be passed in
            meta: "meta",
            topologyMap: "topologyMap",
            projectType: "projectType",
            spanName: "spanName",
            opticalMetaMap: "opticalMetaMap",
            opticalDesignString: "opticalDesignString",
            congoRequired: "congoRequired",
            opticalCutsheetMap: "opticalCutsheetMap",
            opticalEngineerId: "opticalEngineerId",
            fbn: "fbn"
        },
        providerCircuit: {
            // providerCircuitId: "providerCircuitId",
            providerCircuitName: "providerCircuitName",
            // providerCircuitNameForNewRevision: "providerCircuitNameForNewRevision", // Not allowed to be modified
            providerName: "providerName",
            // circuitDesignId: "circuitDesignId", // Not allowed to be modified directly
            circuitDesignIdFromRequest: "circuitDesignIdFromRequest",
            // circuitDesignIdForNewRevision: "circuitDesignIdForNewRevision", // Not allowed to be modified directly
            componentNameFromRequest: "componentNameFromRequest",
            removeCircuitDesignIdFromRequest: "removeCircuitDesignIdFromRequest",
            // auditIdList: "auditIdList", // Should not be passed in
            // createdBy: "createdBy", // Should not be passed in
            // modifiedBy: "modifiedBy", // Should not be passed in
            // createdTime: "createdTime", // Should not be passed in
            // modifiedTime: "modifiedTime", // Should not be passed in
            meta: "meta"
        },
        resource: {
            resourceId: "resourceId",
            resourceName: "resourceName",
            resourceType: "resourceType",
            orderType: "orderType",
            serviceType: "serviceType",
            defaultResource: "defaultResource",
            filterStart: "filterStart",
            filterEnd: "filterEnd",
            businessDeveloper: "businessDeveloper",
            geographicRegion: "geographicRegion",
            cluster: "cluster",
            regionalDefault: "regionalDefault",
            removeResourceFromRequest: "removeResourceFromRequest"
        },
        site: {
            siteId: "siteId",
            siteName: "siteName",
            coloSiteName: "coloSiteName",
            siteType: "siteType",
            address: "address",
            quarterlySpend: "quarterlySpend",
            description: "description",
            managedSite: "managedSite",
            cluster: "cluster",
            market: "market",
            awsRegion: "awsRegion",
            geographicRegion: "geographicRegion",
            colocationOwner: "colocationOwner",
            buildingOwner: "buildingOwner",
            orderIdList: "orderIdList",
            clliCode: "clliCode",
            // nodeIdList: "nodeIdList", Should not be passed in
            // demarcAndCfaIdList: "demarcAndCfaIdList", Should not be passed in
            circuitDesignIdListToAddFromRequest: "circuitDesignIdListToAddFromRequest",
            circuitDesignIdListToRemoveFromRequest: "circuitDesignIdListToRemoveFromRequest",
            // circuitDesignIdFromRequest: "circuitDesignIdFromRequest", * DEPRECATED IN V2 *
            componentNameFromRequest: "componentNameFromRequest",
            // removeCircuitDesignIdFromRequest: "removeCircuitDesignIdFromRequest", * DEPRECATED IN V2 *
            status: "status",
            // auditIdList: "auditIdList", Should not be passed in
            // noteIdList: "noteIdList", Should not be passed in
            // createdBy: "createdBy", Should not be passed in
            // modifiedBy: "modifiedBy", Should not be passed in
            // createdTime: "createdTime", Should not be passed in
            // modifiedTime: "modifiedTime", Should not be passed in
            meta: "meta"
        },
        span: {
            // spanChangeSet: "spanChangeSet", Should not be passed in
            spanId: "spanId",
            spanNumber: "spanNumber",
            // orderId: "orderId", Should not be passed in
            orderIdFromRequest: "orderIdFromRequest",
            workOrderId: "workOrderId",
            mrc: "mrc",
            nrc: "nrc",
            expectedDeliveryDate: "expectedDeliveryDate",
            spanServiceType: "spanServiceType"
            // auditIdList: "auditIdList", Should not be passed in
            // createdBy: "createdBy", Should not be passed in
            // modifiedBy: "modifiedBy", Should not be passed in
            // createdTime: "createdTime", Should not be passed in
            // modifiedTime: "modifiedTime", Should not be passed in
            // meta: "meta", Should not be passed in
        },
        workOrder: {
            // workOrderChangeSet: "workOrderChangeSet", Should not be passed in
            workOrderId: "workOrderId",
            term: "term",
            contractCentralUrl: "contractCentralUrl",
            currency: "currency",
            approvalUrl: "approvalUrl",
            taxUrl: "taxUrl",
            // spanIdList: "spanIdList", Should not be passed in
            orderIdFromRequest: "orderIdFromRequest"
            // auditIdList: "auditIdList", Should not be passed in
            // createdBy: "createdBy", Should not be passed in
            // modifiedBy: "modifiedBy", Should not be passed in
            // createdTime: "createdTime", Should not be passed in
            // modifiedTime: "modifiedTime", Should not be passed in
            // meta: "meta", Should not be passed in
        },
        stageSla: {
            stageSlaId: "stageSlaId",
            stageMap: "stageMap",
            orderVariant: "orderVariant"
            // auditIdList: "auditIdList", Should not be passed in
            // createdBy: "createdBy", Should not be passed in
            // modifiedBy: "modifiedBy", Should not be passed in
            // createdTime: "createdTime", Should not be passed in
            // modifiedTime: "modifiedTime", Should not be passed in
            // meta: "meta", Should not be passed in
        }
    };

    static BUSINESS_NEED_CONSTANTS = {
        scaling: "Scaling",
        newPop: "New PoP"
    };

    static TOPOLOGY_CONSTANTS = {
        remove: "remove",
        position: "position",
        sourceSystem: "sourceSystem",
        linkService: "LinkService",
        fremont: Constants.FREMONT_DATA_SOURCE,
        topologyCustomerFabric: "topologyCustomerFabric",
        dci: "DCI (Metro)",
        congo: "Congo (Long-Haul)",
        subseaInfinera: "Subsea (Infinera Managed)",
        subseaOwned: "Subsea (Owned)",
        bulkFiber: "Bulk Fiber",
        createSpanOrder: "createSpanOrder",
        reuseSpanOrder: "reuseSpanOrder"
    }

    static TOPOLOGY_FIELDS = [
        Constants.TOPOLOGY_CONSTANTS.position,
        Constants.TOPOLOGY_CONSTANTS.sourceSystem,
        Constants.TOPOLOGY_CONSTANTS.linkService,
        Constants.TOPOLOGY_CONSTANTS.remove,
        Constants.ATTRIBUTES.circuitQuantity
    ];

    static TOPOLOGY_SOURCE_TYPES = [
        // Constants.TOPOLOGY_CONSTANTS.linkService, disabled per https://sim.amazon.com/issues/FremontNEST-3101
        Constants.TOPOLOGY_CONSTANTS.fremont
    ];

    static TOPOLOGY_LINK_SERVICE_CUSTOMER_FABRIC_TYPES = [
        Constants.TOPOLOGY_CONSTANTS.dci,
        Constants.TOPOLOGY_CONSTANTS.congo,
        Constants.TOPOLOGY_CONSTANTS.subseaInfinera,
        Constants.TOPOLOGY_CONSTANTS.subseaOwned,
        Constants.TOPOLOGY_CONSTANTS.bulkFiber
    ];

    static OPTICAL_CUTSHEET_CONSTANTS = {
        remove: "remove",
        position: "position",
        fabricPortCutsheet: "fabricPortCutsheet",
        opticalClientCutsheet: "opticalClientCutsheet"
    }

    static LATE_SERVICE_DELIVERY_CREDIT_REASONS = {
        CIRCUIT_DOES_NOT_QUALIFY: "Circuit Does Not Qualify for Late Service Delivery Credit.",
        CIRCUIT_DOES_QUALIFY: "Circuit Does Qualify for Late Service Delivery Credit."
    };

    static JOB_STATUS = {
        SUCCESS: "Success",
        FAILED: "Failed"
    };

    static ICON_TEXT_TYPES = {
        success: "success",
        error: "error",
        secondaryText: "secondaryText"
    }

    static KEEP_KEYS = {
        BLOCKER: [Constants.ATTRIBUTES.blockerId],
        CIRCUIT: [Constants.ATTRIBUTES.circuitDesignId]
    };

    // List of keys to remove from all requests (generally fields we add to augment our state)
    static DENY_LISTED_KEYS = [
        "errorTexts"
    ];

    static ATTACHMENT_TYPES = {
        BERT: "BERT",
        loaA: "loaA",
        loaZ: "loaZ",
        completionNotice: "completionNotice",
        contractAmazonPaper: "contractAmazonPaper",
        contractProviderPaper: "contractProviderPaper",
        escalation: "escalation",
        externalEmail: "externalEmail",
        informational: "informational",
        internalEmail: "internalEmail",
        KMZ: "KMZ",
        quote: "quote",
        RFC: "RFC",
        CSV: "csv"
    };

    static AWS_COMPREHEND_KEYS = {
        CFA: "CFA",
        PROVIDER_CIRCUIT: "PROVIDER_CIRCUIT_ID"
    };

    static BULK_UPDATE_TABLES = {
        ASN: "asn",
        ATTACHMENT: "attachment",
        AUDIT: "audit",
        BILLING_SEGMENT: "billingSegment",
        BLOCKER: "blocker",
        CIRCUIT_DESIGN: "circuitDesign",
        CONTACT: "contact",
        DEMARC_AND_CFA: "demarcAndCfa",
        LAG: "lag",
        NODE: "node",
        NOTE: "note",
        ORDER: "order",
        PATH: "path",
        PORT: "port",
        PROVIDER: "provider",
        PROVIDER_CIRCUIT: "providerCircuit",
        PROVIDER_SERVICE: "providerService",
        SITE: "site",
        SPAN: "span",
        TAG: "tag",
        USER: "user",
        WORK_ORDER: "workOrder"
    };

    static ATTACHMENT_TYPES_LABELS = {
        [Constants.ATTACHMENT_TYPES.BERT]: "BERT Testing",
        [Constants.ATTACHMENT_TYPES.loaA]: "LOA A",
        [Constants.ATTACHMENT_TYPES.loaZ]: "LOA Z",
        [Constants.ATTACHMENT_TYPES.completionNotice]: "Completion Notice",
        [Constants.ATTACHMENT_TYPES.contractAmazonPaper]: "Contract - Amazon Paper",
        [Constants.ATTACHMENT_TYPES.contractProviderPaper]: "Contract - Provider Paper",
        [Constants.ATTACHMENT_TYPES.externalEmail]: "External Email",
        [Constants.ATTACHMENT_TYPES.informational]: "Informational",
        [Constants.ATTACHMENT_TYPES.internalEmail]: "Internal Email",
        [Constants.ATTACHMENT_TYPES.KMZ]: "KMZ",
        [Constants.ATTACHMENT_TYPES.quote]: "Quote",
        [Constants.ATTACHMENT_TYPES.RFC]: "RFC Testing",
        [Constants.ATTACHMENT_TYPES.CSV]: "CSV",
        [Constants.ATTACHMENT_TYPES.escalation]: "Escalation"
    };

    static VALID_ORDER_ATTACHMENT_TYPES = [
        Constants.ATTACHMENT_TYPES.informational,
        Constants.ATTACHMENT_TYPES.externalEmail,
        Constants.ATTACHMENT_TYPES.internalEmail,
        Constants.ATTACHMENT_TYPES.quote,
        Constants.ATTACHMENT_TYPES.contractAmazonPaper,
        Constants.ATTACHMENT_TYPES.contractProviderPaper
    ];

    static VALID_PROVIDER_SERVICE_ATTACHMENT_TYPES = [
        Constants.ATTACHMENT_TYPES.escalation
    ];

    static VALID_BULK_UPDATE_ATTACHMENT_TYPES = [
        Constants.ATTACHMENT_TYPES.CSV
    ];

    static ENCRYPTION_TYPES = {
        NONE: "None",
        DWDM: "DWDM",
        LEVER: "Lever",
        OPTICAL: "Optical",
        BRIGGS_WITH_OLS: "BRIGGS with OLS",
        BRIGGS_WITH_BF_ONLY: "BRIGGS with BF only",
        BRIGGS_ON_ONLY_A_SIDE_WITH_LEVER: "BRIGGS on only A side with Lever",
        BRIGGS_ON_ONLY_A_SIDE_WITHOUT_LEVER: "BRIGGS on only A side without Lever",
        BRIGGS_ON_ONLY_Z_SIDE_WITH_LEVER: "BRIGGS on only Z side with Lever",
        BRIGGS_ON_ONLY_Z_SIDE_WITHOUT_LEVER: "BRIGGS on only Z side without Lever",
        BRIGGS_ON_BOTH_SIDES: "BRIGGS on both sides",
        BRIGGS_THROUGH_LEASED: "BRIGGS through leased"
    };

    static SERVICE_TYPES = {
        SFP: "Settlement Free Peering",
        IP_TRANSIT: "IP Transit",
        PAID_PEERING: "Paid Peering",
        INTERNET_EXCHANGE: "Internet Exchange",
        BACKBONE: "Backbone"
    };

    static SERVICE_TYPES_FOR_PROVIDER_SERVICE_CREATION = {
        ...this.SERVICE_TYPES,
        OPTICAL: "Optical"
    };

    static INTERCONNECT_SERVICE_TYPES = [
        Constants.SERVICE_TYPES.SFP,
        Constants.SERVICE_TYPES.IP_TRANSIT,
        Constants.SERVICE_TYPES.PAID_PEERING,
        Constants.SERVICE_TYPES.INTERNET_EXCHANGE
    ];

    static ORDER_GEOGRAPHIC_REGION = {
        APAC: "APAC",
        EMEA: "EMEA",
        AMER: "AMER"
    };


    static CUSTOMER_FABRICS = {
        BFB_PATH: "BFB Path",
        BACKBONE_SPAN: "Backbone Span",
        CHINA_PATH: "China Path",
        CLOUD_FRONT: "CloudFront",
        DX: "DX",
        FINAL_CAT_PATH: "FinalCat Path",
        GOV_CLOUD: "GovCloud",
        IP_PEERING_BACKHAUL: "IP/Peering Backhaul",
        MVP: "MVP",
        PAYMENTS_INFRA: "Payments Infra",
        TWITCH: "Twitch",
        PRIME_VIDEO: "Prime Video"
    };

    static CUSTOMER_FABRICS_DESCRIPTIONS = {
        [Constants.CUSTOMER_FABRICS.BFB_PATH]:
            "Layer 2/3 router-to-router IP Path capacity supporting Big Fabric Backbone (BFB) services.",
        [Constants.CUSTOMER_FABRICS.BACKBONE_SPAN]:
            "Includes both Big Fabric Backbone (BFB, aka classic Juniper Backbone) and FinalCat (FNC) fabrics.",
        [Constants.CUSTOMER_FABRICS.CHINA_PATH]:
            "Layer 2/3 router-to-router IP Path capacity supporting the AWS China Backbone.",
        [Constants.CUSTOMER_FABRICS.CLOUD_FRONT]: "Layer 1 optical Span capacity supporting CloudFront services.",
        [Constants.CUSTOMER_FABRICS.DX]: "Layer 1 optical Span capacity supporting Direct Connect (DX) services.",
        [Constants.CUSTOMER_FABRICS.FINAL_CAT_PATH]:
            "Layer 2/3 router-to-router IP Path capacity supporting FinalCat (FNC) services.",
        [Constants.CUSTOMER_FABRICS.GOV_CLOUD]: "Layer 1 optical Span capacity supporting GovCloud services.",
        [Constants.CUSTOMER_FABRICS.IP_PEERING_BACKHAUL]:
            "Layer 1 optical backhaul capacity supporting remote (off-network) extended Peering & Transit services.",
        [Constants.CUSTOMER_FABRICS.MVP]: "Layer 1 optical Span capacity supporting MVP services.",
        [Constants.CUSTOMER_FABRICS.PAYMENTS_INFRA]:
            "Layer 1 optical Span capacity supporting Payments Infrastructure services.",
        [Constants.CUSTOMER_FABRICS.TWITCH]: "Layer 1 optical Span capacity supporting Twitch services.",
        [Constants.CUSTOMER_FABRICS.PRIME_VIDEO]: "Layer 1 optical Span capacity supporting Prime Video services."
    };

    static SPAN_CUSTOMER_FABRICS = [
        Constants.CUSTOMER_FABRICS.BACKBONE_SPAN
    ];

    static CONSUMABLE_CUSTOMER_FABRICS = [
        Constants.CUSTOMER_FABRICS.BACKBONE_SPAN,
        Constants.CUSTOMER_FABRICS.DX,
        Constants.CUSTOMER_FABRICS.CLOUD_FRONT,
        Constants.CUSTOMER_FABRICS.PAYMENTS_INFRA,
        Constants.CUSTOMER_FABRICS.GOV_CLOUD,
        Constants.CUSTOMER_FABRICS.IP_PEERING_BACKHAUL,
        Constants.CUSTOMER_FABRICS.MVP,
        Constants.CUSTOMER_FABRICS.TWITCH,
        Constants.CUSTOMER_FABRICS.PRIME_VIDEO
    ];

    static PATH_CUSTOMER_FABRICS = [
        Constants.CUSTOMER_FABRICS.BFB_PATH,
        Constants.CUSTOMER_FABRICS.FINAL_CAT_PATH,
        Constants.CUSTOMER_FABRICS.CHINA_PATH
    ];

    static ENCRYPTION_PATH_CUSTOMER_FABRICS = [
        Constants.CUSTOMER_FABRICS.BFB_PATH,
        Constants.CUSTOMER_FABRICS.FINAL_CAT_PATH
    ];

    static TT_URL_CUSTOMER_FABRICS = [
        Constants.CUSTOMER_FABRICS.CLOUD_FRONT,
        Constants.CUSTOMER_FABRICS.PAYMENTS_INFRA,
        Constants.CUSTOMER_FABRICS.GOV_CLOUD,
        Constants.CUSTOMER_FABRICS.MVP,
        Constants.CUSTOMER_FABRICS.DX,
        Constants.CUSTOMER_FABRICS.TWITCH,
        Constants.CUSTOMER_FABRICS.PRIME_VIDEO
    ]

    static RESOURCE_ORDER_TYPES_ARRAY = [
        {
            [PolarisUtils.OPTION_VALUE_KEY]: Constants.ORDER_TYPES.INSTALL,
            [PolarisUtils.OPTION_LABEL_KEY]: Constants.BACKEND_TO_FRONTEND_MAP[Constants.ORDER_TYPES.INSTALL]
        },
        {
            [PolarisUtils.OPTION_VALUE_KEY]: Constants.ORDER_TYPES.CHANGE,
            [PolarisUtils.OPTION_LABEL_KEY]: Constants.BACKEND_TO_FRONTEND_MAP[Constants.ORDER_TYPES.CHANGE]
        },
        {
            [PolarisUtils.OPTION_VALUE_KEY]: Constants.ORDER_TYPES.DECOMMISSION,
            [PolarisUtils.OPTION_LABEL_KEY]: Constants.BACKEND_TO_FRONTEND_MAP[Constants.ORDER_TYPES.DECOMMISSION]
        }
    ];

    static POLARIS_FILTER_PROPERTY_KEYS = {
        ORDER_STATUS: "objectStatusColumnSearchable"
    }
    static POLARIS_FILTER_OPERATORS = {
        notEqual: "!=",
        equals: "="
    }
    static POLARIS_FILTER_LOGICAL_OPERATORS = {
        and: "and"
    }
    static POLARIS_FILTER_VALUES = {
        cancelled: "Cancelled",
        completed: "Completed",
        none: ""
    }
    static POLARIS_FILTER_PROPERTIES = {
        [Constants.POLARIS_FILTER_PROPERTY_KEYS.ORDER_STATUS]: {
            operators: [
                Constants.POLARIS_FILTER_OPERATORS.notEqual,
                Constants.POLARIS_FILTER_OPERATORS.equals
            ],
            propertyLabel: "Status",
            groupValuesLabel: "Status values"
        }
    }

    static ASN_SERVICE_TYPES = [
        Constants.SERVICE_TYPES.SFP,
        Constants.SERVICE_TYPES.IP_TRANSIT,
        Constants.SERVICE_TYPES.PAID_PEERING
    ];

    static BLOCKER_VALUES = {
        blockerCircuitToAdd: "blockerCircuitToAdd",
        newNote: "newNote"
    };

    static CIRCUIT_DESIGN_VALUES = {
        componentGroup: "componentGroup",
        customComponent: "customComponent",
        crossConnect: "crossConnect",
        demarcPoint: "demarcPoint",
        cfa: "cfa",
        fremontCircuit: "fremontCircuit"
    };

    static STATUS = {
        active: "Active",
        inactive: "Inactive",
        testing: "Testing",
        ready: "Ready",
        pendingPeer: "Pending Peer",
        pendingDCO: "Pending DCO",
        success: "Success",
        implementation: "Implementation",
        resolved: "Resolved",
        complete: "Complete",
        incomplete: "Incomplete"
    };

    static YES_NO = {
        yes: "Yes",
        no: "No"
    };

    static MAP_INDEXES = {
        keyIndex: 0,
        valueIndex: 1
    };

    static LIFECYCLE_STAGES = {
        active: "Active",
        cancelled: "Cancelled",
        decommissioned: "Decommissioned",
        design: "Design",
        deprecated: "Deprecated",
        pendingDecommission: "Pending Decommission",
        provisioning: "Provisioning"
    };

    static TT_TYPES = {
        dcoTTA: "dcoTTA",
        dcoTTZ: "dcoTTZ",
        opticalTTA: "opticalTTA",
        opticalTTZ: "opticalTTZ"
    }

    static TT_TYPES_STATUS = {
        dcoTTA: "dcoTTAStatus",
        dcoTTZ: "dcoTTZStatus",
        opticalTTA: "opticalTTAStatus",
        opticalTTZ: "opticalTTZStatus"
    }

    static OPTICAL_CUSTOMER_FABRICS = [
        Constants.CUSTOMER_FABRICS.DX,
        Constants.CUSTOMER_FABRICS.TWITCH,
        Constants.CUSTOMER_FABRICS.PRIME_VIDEO
    ];

    static COMPONENT_NAMES = {
        crossConnectA: "crossConnectA",
        crossConnectZ: "crossConnectZ",
        demarcAndCfaA: "demarcAndCfaA",
        demarcAndCfaZ: "demarcAndCfaZ",
        fremontCircuit: "Fremont Circuit",
        linkService: "LinkService",
        interfaceA: "interfaceA",
        interfaceZ: "interfaceZ",
        lagA: "lagA",
        lagZ: "lagZ",
        leverA: "leverA",
        leverZ: "leverZ",
        leverAInternalInterface: "leverAInternalInterface",
        leverZInternalInterface: "leverZInternalInterface",
        leverAExternalInterface: "leverAExternalInterface",
        leverZExternalInterface: "leverZExternalInterface",
        nodeA: "nodeA",
        nodeZ: "nodeZ",
        portA: "portA",
        portZ: "portZ",
        providerCircuitA: "providerCircuitA",
        routerA: "routerA",
        routerZ: "routerZ",
        siteA: "siteA",
        siteZ: "siteZ",
        ttId: "ttId",
        ttStatus: "ttStatus",
        unitA: "unitA",
        unitZ: "unitZ"
    };

    static IGNORE_KEYS = {
        BLOCKER: [
            Constants.ATTRIBUTES.circuitDesignIdList,
            Constants.ATTRIBUTES.providerName,
            Constants.ATTRIBUTES.noteIdList,
            Constants.BLOCKER_VALUES.newNote
        ],
        CIRCUIT: [
            Constants.COMPONENT_NAMES.portA,
            Constants.COMPONENT_NAMES.portZ,
            Constants.COMPONENT_NAMES.lagA,
            Constants.COMPONENT_NAMES.lagZ,
            Constants.COMPONENT_NAMES.leverA,
            Constants.COMPONENT_NAMES.leverAExternalInterface,
            Constants.COMPONENT_NAMES.leverAInternalInterface,
            Constants.COMPONENT_NAMES.leverZ,
            Constants.COMPONENT_NAMES.leverZExternalInterface,
            Constants.COMPONENT_NAMES.leverZInternalInterface,
            Constants.COMPONENT_NAMES.siteA,
            Constants.COMPONENT_NAMES.siteZ,
            Constants.COMPONENT_NAMES.nodeA,
            Constants.COMPONENT_NAMES.nodeZ,
            Constants.COMPONENT_NAMES.demarcAndCfaA,
            Constants.COMPONENT_NAMES.demarcAndCfaZ,
            Constants.COMPONENT_NAMES.providerCircuitA,
            Constants.COMPONENT_NAMES.unitA,
            Constants.COMPONENT_NAMES.unitZ,
            Constants.ATTRIBUTES.siteAId,
            Constants.ATTRIBUTES.siteZId,
            Constants.ATTRIBUTES.nodeId,
            Constants.ATTRIBUTES.lagId,
            Constants.ATTRIBUTES.unitId,
            Constants.ATTRIBUTES.demarcAndCfaId,
            Constants.ATTRIBUTES.portId,
            Constants.ATTRIBUTES.providerCircuitId,
            Constants.ATTRIBUTES.positionMap,
            Constants.ATTRIBUTES.requiredFieldsCompletedMap
        ]
    };

    static COMPONENT_HEADER = {
        leverA: "Lever A",
        leverZ: "Lever Z",
        leverAInternalInterface: "Lever A Internal Interface",
        leverZInternalInterface: "Lever Z Internal Interface",
        leverAExternalInterface: "Lever A External Interface",
        leverZExternalInterface: "Lever Z External Interface",
        interfaceA: "Interface A",
        interfaceZ: "Interface Z",
        portA: "Port A",
        portZ: "Port Z",
        routerA: "Router A",
        routerZ: "Router Z"
    };

    static COMPONENT_DESCRIPTION = {
        router: "Router is a type of port",
        interface: "Interface A for this circuitDesign",
        lever: "Levers are a type of encrypted node",
        leverInternalInterface: "Lever Internal Interface is a type of encrypted port",
        leverExternalInterface: "Lever External Interface is a type of encrypted port"
    };

    static COMPONENT_TYPES = {
        demarcAndCfa: "demarcAndCfa",
        lag: "lag",
        port: "port",
        providerCircuit: "providerCircuit",
        node: "node",
        unit: "unit",
        site: "site",
        crossConnect: "crossConnect",
        customComponent: "customComponent",
        aClientPort: "aClientPort"
    };

    static COMPONENT_LABELS = {
        router: "providerCircuit",
        interface: "interface",
        lever: "lever",
        leverExternalInterface: "leverExternalInterface",
        leverInternalInterface: "leverInternalInterface"
    };

    static COMPONENT_KEYS = {
        name: "name",
        type: "type",
        uuid: "uuid",
        group: "group",
        componentGroup: "componentGroup",
        siteGroup: "siteGroup",
        snapshot: "snapshot"
    };

    static COMPONENT_GROUPS = {
        demarcAndCfa: "Demarc/CFA Group",
        crossConnect: "Cross Connect"
    };

    static STAGE_NAMES = {
        awsProvisioning: "awsProvisioning",
        backboneImplementation: "backboneImplementation",
        cabling: "cabling",
        cablingOrder: "cablingOrder",
        carrierBuild: "carrierBuild",
        carrierConfirmation: "carrierConfirmation",
        carrierNotification: "carrierNotification",
        circuitActivation: "circuitActivation",
        completeOrder: "completeOrder",
        costOut: "costOut",
        createCircuits: "createCircuits",
        demarcAndCfaStage: "demarcAndCfaStage",
        filter: "filter",
        hardwareDelivery: "hardwareDelivery",
        implementation: "implementation",
        ipAllocation: "ipAllocation",
        ipTesting: "ipTesting",
        lineFiber: "lineFiber",
        mcmExecution: "mcmExecution",
        newOrderInformation: "newOrderInformation",
        opticalCutsheet: "opticalCutsheet",
        opticalPrepWork: "opticalPrepWork",
        opticalTopology: "opticalTopology",
        orderAcceptance: "orderAcceptance",
        pathDesign: "pathDesign",
        portReservation: "portReservation",
        releasePort: "releasePort",
        selectCircuits: "selectCircuits",
        submitForApproval: "submitForApproval",
        topology: "topology",
        troubleshooting: "troubleshooting",
        turnDown: "turnDown",
        vendorBuild: "vendorBuild",
        verifyStopBilling: "verifyStopBilling",
        workOrderExecution: "workOrderExecution"
    };

    static PROJECT_SPECIFIC_BACKBONE_FABRICS = [
        Constants.CUSTOMER_FABRICS.BACKBONE_SPAN
    ]

    static PROVIDER_SPECIFIC_BACKBONE_FABRICS = [
        Constants.CUSTOMER_FABRICS.BACKBONE_SPAN,
        Constants.CUSTOMER_FABRICS.BFB_PATH,
        Constants.CUSTOMER_FABRICS.FINAL_CAT_PATH
    ]

    static BACKBONE_SPAN_OPTICAL_COLUMNS = [
        Constants.STAGE_NAMES.lineFiber,
        Constants.STAGE_NAMES.opticalPrepWork,
        Constants.STAGE_NAMES.awsProvisioning,
        Constants.STAGE_NAMES.hardwareDelivery,
        Constants.STAGE_NAMES.vendorBuild,
        Constants.STAGE_NAMES.cablingOrder
    ]

    static BACKBONE_PATH_OPTICAL_COLUMNS = [
        Constants.STAGE_NAMES.pathDesign,
        Constants.STAGE_NAMES.opticalTopology,
        Constants.STAGE_NAMES.opticalCutsheet,
        Constants.STAGE_NAMES.circuitActivation
    ]

    static ORDER_TYPE_STAGE_MAP = {
        BACKBONE_SPAN_INSTALL_STAGES: [
            Constants.STAGE_NAMES.newOrderInformation,
            Constants.STAGE_NAMES.createCircuits,
            Constants.STAGE_NAMES.workOrderExecution,
            Constants.STAGE_NAMES.cabling,
            Constants.STAGE_NAMES.backboneImplementation,
            // Optical Only Starting here
            ...Constants.BACKBONE_SPAN_OPTICAL_COLUMNS,
            // Optical Only Ending here
            Constants.STAGE_NAMES.demarcAndCfaStage,
            Constants.STAGE_NAMES.completeOrder
        ],
        BACKBONE_PATH_INSTALL_STAGES: [
            Constants.STAGE_NAMES.newOrderInformation,
            Constants.STAGE_NAMES.createCircuits,
            Constants.STAGE_NAMES.topology,
            Constants.STAGE_NAMES.carrierBuild,
            Constants.STAGE_NAMES.submitForApproval,
            Constants.STAGE_NAMES.orderAcceptance,
            Constants.STAGE_NAMES.portReservation,
            Constants.STAGE_NAMES.cabling,
            Constants.STAGE_NAMES.troubleshooting,
            Constants.STAGE_NAMES.implementation,
            // Optical Only Starting here
            ...Constants.BACKBONE_PATH_OPTICAL_COLUMNS,
            //  Optical Only Ending here
            Constants.STAGE_NAMES.completeOrder
        ],
        SETTLEMENT_FREE_PEERING_INSTALL_CHANGE_STAGES: [
            Constants.STAGE_NAMES.orderAcceptance,
            Constants.STAGE_NAMES.portReservation,
            Constants.STAGE_NAMES.cabling,
            Constants.STAGE_NAMES.ipAllocation,
            Constants.STAGE_NAMES.ipTesting,
            Constants.STAGE_NAMES.troubleshooting,
            Constants.STAGE_NAMES.implementation,
            Constants.STAGE_NAMES.completeOrder
        ],
        SETTLEMENT_FREE_PEERING_DECOM_STAGES: [
            Constants.STAGE_NAMES.orderAcceptance,
            Constants.STAGE_NAMES.filter,
            Constants.STAGE_NAMES.turnDown,
            Constants.STAGE_NAMES.cabling,
            Constants.STAGE_NAMES.releasePort,
            Constants.STAGE_NAMES.carrierNotification,
            Constants.STAGE_NAMES.carrierConfirmation,
            Constants.STAGE_NAMES.completeOrder
        ]
    }

    static STAGE_SLA_TYPES = {
        SETTLEMENT_FREE_PEERING_INSTALL_STAGES: "Interconnect Install",
        BACKBONE_PATH_INSTALL_STAGES: "Backbone Path Install",
        BACKBONE_SPAN_INSTALL_STAGES: "Backbone Span Install",
        SETTLEMENT_FREE_PEERING_DECOM_STAGES: "Interconnect Decom"
    }

    static STAGE_STATUSES = {
        inProgress: "inProgress",
        completed: "completed",
        notStarted: "notStarted",
        cancelled: "cancelled"
    };

    static PROJECT_TYPES = {
        metroNewSpan: "Metro New Span",
        longHaulNewSpan: "Long Haul New Span",
        trunkScaling: "Trunk Scaling",
        clientScaling: "Client Scaling",
        decommissionSpan: "Decommission Span"
    }

    static GLOBAL_SEARCH_OBJECT_OPTIONS = {
        All: "All",
        asn: "ASN",
        circuitDesign: "Circuit Design",
        order: "Order",
        provider: "Provider",
        site: "Site"
    }

    static SEARCH_OBJECTS = {
        asns: "asns",
        circuitDesigns: "circuitDesigns",
        orders: "orders",
        providers: "providers",
        sites: "sites"
    }

    static GLOBAL_SEARCH_IDS = {
        globalSearchEntitySelect: "globalSearchEntitySelect",
        globalSearchType: "globalSearchType",
        globalSearchSubStringInput: "globalSearchSubStringInput",
        globalSearchSubStringSearch: "globalSearchSubStringSearch",
        multi_match: "multi_match",
        query_string: "query_string"
    }

    static STATUS_TEXTS = {
        loading: "Loading",
        loadingAsns: "Loading ASNs",
        loadingAttachments: "Loading Attachments",
        loadingAudits: "Loading Audits",
        loadingAvailableServices: "Loading Available Services",
        loadingCircuitDesigns: "Loading Circuit Designs",
        loadingContacts: "Loading Contacts",
        loadingComponents: "Loading Components",
        loadingJobs: "Loading Jobs",
        loadingNodes: "Loading Nodes",
        loadingProviders: "Loading Providers",
        loadingServices: "Loading Services",
        loadingSites: "Loading Sites",
        loadingResources: "Loading Resources",
        loadingLags: "Loading Lags",
        loadingNotes: "Loading Notes",
        loadingOrders: "Loading Orders",
        loadingOptions: "Loading Options",
        loadingPorts: "Loading Ports",
        loadingValidPaths: "Loading Paths"
    };

    static CIRCUIT_DESIGN_TAB_IDS = {
        DESIGN_TAB_ID: "circuitDesign",
        NOTES_TAB_ID: "notes",
        ORDERS_TAB_ID: "circuitOrders",
        AUDIT_TAB_ID: "circuitAudits",
        ATTACHMENTS_TAB_ID: "circuitAttachments"
    };

    static OPERATIONS_PAGE_TAB_IDS = {
        BULK_UPDATE: "bulkUpdateTab"
    };

    // The names for stages on the backend and frontend are different. This object is used to map backend stages
    // the their appropriate frontend display name
    static BACKEND_TO_FRONTEND_STAGE_MAP = {
        [Constants.STAGE_NAMES.awsProvisioning]: "AWS Provisioning",
        [Constants.STAGE_NAMES.backboneImplementation]: "Backbone Implementation",
        [Constants.STAGE_NAMES.cabling]: "Cabling",
        [Constants.STAGE_NAMES.cablingOrder]: "Cabling Order",
        [Constants.STAGE_NAMES.carrierBuild]: "Provider Build",
        [Constants.STAGE_NAMES.carrierConfirmation]: "Carrier Confirmation",
        [Constants.STAGE_NAMES.carrierNotification]: "Carrier Notification",
        [Constants.STAGE_NAMES.circuitActivation]: "Circuit Activation",
        [Constants.STAGE_NAMES.completeOrder]: "Order Completion",
        [Constants.STAGE_NAMES.costOut]: "Cost Out",
        [Constants.STAGE_NAMES.createCircuits]: "Design",
        [Constants.STAGE_NAMES.demarcAndCfaStage]: "Demarc & CFA",
        [Constants.STAGE_NAMES.filter]: "Filter",
        [Constants.STAGE_NAMES.hardwareDelivery]: "Hardware Delivery",
        [Constants.STAGE_NAMES.implementation]: "Implementation",
        [Constants.STAGE_NAMES.ipAllocation]: "IP Allocation",
        [Constants.STAGE_NAMES.ipTesting]: "IP Testing",
        [Constants.STAGE_NAMES.lineFiber]: "Line Fiber Allocated/Provided",
        [Constants.STAGE_NAMES.mcmExecution]: "Mcm Execution",
        [Constants.STAGE_NAMES.newOrderInformation]: "New Order Information",
        [Constants.STAGE_NAMES.opticalCutsheet]: "Optical Cutsheet",
        [Constants.STAGE_NAMES.opticalPrepWork]: "Optical NE Prep Work",
        [Constants.STAGE_NAMES.opticalTopology]: "Optical Topology",
        [Constants.STAGE_NAMES.orderAcceptance]: "Order Acceptance",
        [Constants.STAGE_NAMES.pathDesign]: "Path Design",
        [Constants.STAGE_NAMES.portReservation]: "Port Reservation",
        [Constants.STAGE_NAMES.releasePort]: "Release Port",
        [Constants.STAGE_NAMES.selectCircuits]: "Select Circuit Designs",
        [Constants.STAGE_NAMES.submitForApproval]: "Submit for Approval",
        [Constants.STAGE_NAMES.topology]: "Topology",
        [Constants.STAGE_NAMES.troubleshooting]: "Troubleshooting",
        [Constants.STAGE_NAMES.turnDown]: "Turn Down",
        [Constants.STAGE_NAMES.vendorBuild]: "Vendor Build",
        [Constants.STAGE_NAMES.verifyStopBilling]: "Verify Stop Billing",
        [Constants.STAGE_NAMES.workOrderExecution]: "Work Order Execution"
    };

    static DEPENDENCY_LINK_REFERENCES = {
        [Constants.STAGE_NAMES.cabling]: {
            ref: React.createRef()
        },
        [Constants.STAGE_NAMES.carrierConfirmation]: {
            ref: React.createRef()
        },
        [Constants.STAGE_NAMES.carrierNotification]: {
            ref: React.createRef()
        },
        [Constants.STAGE_NAMES.verifyStopBilling]: {
            ref: React.createRef()
        },
        [Constants.STAGE_NAMES.costOut]: {
            ref: React.createRef()
        },
        [Constants.STAGE_NAMES.createCircuits]: {
            ref: React.createRef()
        },
        [Constants.STAGE_NAMES.filter]: {
            ref: React.createRef()
        },
        [Constants.STAGE_NAMES.implementation]: {
            ref: React.createRef()
        },
        [Constants.STAGE_NAMES.ipAllocation]: {
            ref: React.createRef()
        },
        [Constants.STAGE_NAMES.newOrderInformation]: {
            ref: React.createRef()
        },
        [Constants.STAGE_NAMES.portReservation]: {
            ref: React.createRef()
        },
        [Constants.STAGE_NAMES.releasePort]: {
            ref: React.createRef()
        },
        [Constants.STAGE_NAMES.submitForApproval]: {
            ref: React.createRef()
        },
        [Constants.STAGE_NAMES.troubleshooting]: {
            ref: React.createRef()
        },
        [Constants.STAGE_NAMES.turnDown]: {
            ref: React.createRef()
        },
        [Constants.STAGE_NAMES.orderAcceptance]: {
            ref: React.createRef()
        },
        [Constants.STAGE_NAMES.backboneImplementation]: {
            ref: React.createRef()
        },
        [Constants.STAGE_NAMES.selectCircuits]: {
            ref: React.createRef()
        },
        [Constants.STAGE_NAMES.workOrderExecution]: {
            ref: React.createRef()
        },
        [Constants.STAGE_NAMES.demarcAndCfaStage]: {
            ref: React.createRef()
        },
        [Constants.STAGE_NAMES.topology]: {
            ref: React.createRef()
        },
        [Constants.STAGE_NAMES.ipTesting]: {
            ref: React.createRef()
        },
        [Constants.STAGE_NAMES.carrierBuild]: {
            ref: React.createRef()
        },
        [Constants.STAGE_NAMES.pathDesign]: {
            ref: React.createRef()
        },
        [Constants.STAGE_NAMES.opticalCutsheet]: {
            ref: React.createRef()
        },
        [Constants.STAGE_NAMES.lineFiber]: {
            ref: React.createRef()
        },
        [Constants.STAGE_NAMES.opticalPrepWork]: {
            ref: React.createRef()
        },
        [Constants.STAGE_NAMES.hardwareDelivery]: {
            ref: React.createRef()
        },
        [Constants.STAGE_NAMES.awsProvisioning]: {
            ref: React.createRef()
        },
        [Constants.STAGE_NAMES.cablingOrder]: {
            ref: React.createRef()
        },
        [Constants.STAGE_NAMES.vendorBuild]: {
            ref: React.createRef()
        },
        [Constants.STAGE_NAMES.mcmExecution]: {
            ref: React.createRef()
        },
        [Constants.STAGE_NAMES.opticalTopology]: {
            ref: React.createRef()
        },
        [Constants.STAGE_NAMES.circuitActivation]: {
            ref: React.createRef()
        }
    };

    static TEMPLATE_TYPES = {
        backboneA: "Backbone: Site A",
        backboneZ: "Backbone: Site Z",
        backboneMCMCutsheet: "Client Cutsheet",
        interconnectA: "Interconnect: Site A"
    }

    static SEARCH_PARAMETER_OPTIONS = [
        {
            label: "Circuit Bandwidth",
            id: "circuitBandwidth"
        },
        {
            label: "Circuit ID",
            id: "circuitDesignNumber"
        },
        {
            label: "Order ID",
            id: "orderId"
        }
    ];

    static SEARCH_PARAMETER_OPTIONS_PATH_DECOMCHANGE = [
        {
            label: "Circuit Bandwidth",
            id: "circuitBandwidth"
        },
        {
            label: "Circuit ID",
            id: "circuitDesignNumber"
        },
        {
            label: "Order ID",
            id: "orderId"
        },
        {
            label: "Path Name",
            id: "pathName"
        }
    ];

    static SEARCH_PARAMETER_OPTIONS_BILLING = [
        {
            label: Constants.ATTRIBUTE_LABELS.providerCircuitName,
            id: "providerCircuitName"
        },
        {
            label: "Order ID",
            id: "orderId"
        }
    ];

    static DESCRIPTION_TEXTS = {
        noServicesForProvider: "No services exist for this provider.",
        noSites: "No sites exist.",
        asnRegion: "Asn Region",
        asnSessionType: "Session Type"
    };

    static PLACEHOLDER_TEXTS = {
        noAsnsForProvider: "No ASNs exist for this provider",
        noContactsForProvider: "No contacts exist for this provider",
        selectIsProtected: "Select Is Protected",
        selectSpanType: "Select Span Type"
    };

    static DISPLAY_LABEL = {
        providers: "Provider(s):",
        buildingOwner: "Building Owner:",
        colocationOwner: "Colocation Owner:"
    };

    // Lint does not allow "useless" escape characters (such as \). The following three regex patterns use these
    // "useless" escape characters in their regex patterns. We disable the error in this file in the line below
    /* eslint-disable no-useless-escape */
    static REGEX_PATTERNS = {
        latAndLong: /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
        website: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/g,
        email: /^\S+@\S+\.\S+$/,
        // eslint-disable-next-line max-len
        ipv4Regex: /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/((?:[0-9])|(?:[1-2][0-9])|(?:3[0-2])))?$/,
        // eslint-disable-next-line max-len
        ipv4WithoutSubnetRegex: /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/,
        // eslint-disable-next-line max-len
        ipv6Regex: /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))(\/([0-9]{1,2}|1[01][0-9]|12[0-8]))?$/,
        // eslint-disable-next-line max-len
        ipv6WithoutSubnetRegex: /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/,
        uuidInAudit: /\["[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}.*"\]/g,
        uuidV4: /[0-9a-zA-Z]{8}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{12}/,
        orderId: /ORDER-[0-9]+/g,
        pathName: [
            // Example P-CDG050-IAD079-01-[Metro]-[BF]-[Infinera]-[Metro]
            new RegExp("^P-([A-Z]{3}[0-9]{3})-([A-Z]{3}[0-9]{3})-[0-9]{2}(-[?[A-Za-z]+]?){1,4}$")
        ],
        // Example: pdx123-pdx4_pp1 or pdx1-pdx4_pp1234 or pdx1-pdx4_bf or atl56-iah62_l123
        opticalDesignString: /^[A-z]{3}[0-9]{1,3}-([A-z]{3}|[A-z]{0})[0-9]{1,3}_((pp|l)[0-9]{1,4}|bf)$/,
        siteRegex: /^[A-z]{3}[0-9]{1,3}$/
    };

    static ERROR_STRINGS = {
        blankAddressInput: "Required if entering a mailing address.",
        blankAsnIdErrorText: "ASN ID cannot be blank.",
        blankContactNameErrorText: "Contact name cannot be blank.",
        blankInput: "This is a required input and cannot be blank.",
        blankLevelErrorText: "Escalation level cannot be blank.",
        blankProviderErrorText: "Provider name cannot be blank.",
        blankProviderServiceErrorText: "Provider Service cannot be blank.",
        blankRegionErrorText: "Region name cannot be blank.",
        blankMultiSelectErrorText: "Must select at least one option.",
        blankSessionTypeErrorText: "Session type cannot be blank.",
        blankSiteNameErrorText: "Site name cannot be blank.",
        buildingOwnerOrColoOwner: "A site must have either a building owner or a colocation owner.",
        cannotBeZero: "This value cannot be zero.",
        invalidFile: "File type not valid.",
        invalidAsn: "Asn does not exist in Fremont.",
        invalidInput: "Item does not exist in Fremont.",
        invalidProvider: "Provider does not exist in Fremont.",
        invalidSite: "Site does not exist in Fremont.",
        missingCircuit: "Must select a circuit from the table",
        missingFile: "Must select a file for upload.",
        missingSessionTypeForRegionErrorText: "Session type must be defined for a region.",
        missingRegionForSessionTypeErrorText: "Region must be defined for a session type.",
        mustBeZero: "This value must be zero.",
        providerAsParentProvider: "You cannot select the current provider as a parent provider",
        invalidLatencyRange: "Value must be between 0 to 300.",
        SteadyStateMoreThanProtectState: "Max Latency Steady State must be less than Max Latency Protect State",
        invalidOpticalDesignString: `One or more spans in optical design string does not match the regex pattern ${Constants.REGEX_PATTERNS.opticalDesignString.toString()}`
    };

    static EMPTY_MESSAGES = {
        provider: "No matching provider(s) found",
        site: "No matching site(s) found",
        tag: "No matching tags(s) found",
        generic: "No matching item(s) found"
    };

    static DYNAMIC_INPUT_TYPES = {
        asn: "asn",
        region: "region",
        contact: "contact",
        provider: "provider",
        escalationPath: "escalationPath",
        searchParams: "searchParams",
        site: "site"
    };

    static VALIDATE_INFO_OPTIONS = {
        required: "required",
        blankProvider: "blankProvider",
        blankProviderService: "blankProviderService",
        blankRegion: "blankRegion",
        blankContact: "blankContact",
        blankLevel: "blankLevel"
    };

    static PHONE_OR_FAX = {
        phone: "phone",
        fax: "fax"
    };

    static RESOURCE_TYPES = {
        backBoneEngineer: "BackBoneEngineer",
        ipEngineer: "IPEngineer",
        provisioner: "Provisioner",
        bizops: "BusinessOperations",
        businessDeveloper: "BusinessDeveloper"
    };

    // Used primiarily in OrderCreatePage, because we put the backboneEngineer and ipEngineer in the same field
    static ENGINEER = "engineer";

    static PATH_NAME_VALIDATION_WIKI =
        "https://amazonprojects.corp.amazon.com/sites/NPA/Key_Initiatives/SRLG_Program/Pages/Path_Span_Naming.aspx";

    static ERROR_TYPES = {
        abort: "AbortError",
        permission: "PermissionError",
        input: "InputError",
        backend: "BackendError",
        noVendors: "NoVendors"
    };

    static FOOTER_STRINGS = {
        bugText: "Bug Report",
        featureText: "Feature Request",
        fremontBugLink: "https://t.corp.amazon.com/create/templates/b749d140-bf14-46ee-8ca0-b92cafa591b8",
        fremontFeatureLink: "https://issues.amazon.com/issues/create?template=5dc5e228-ad7b-4818-b92b-c40df61c423c",
        fremontHelpLink: "https://w.amazon.com/bin/view/Networking/Fremont/Customers/UserGuides#HUserGuidesandDemos",
        fremontHelpText: "Fremont FAQ/User Guide",
        haloBugLink: "https://t.corp.amazon.com/create/templates/1e635901-460e-4aa5-b849-accf776306ad",
        haloFeatureLink: "https://sim.amazon.com/issues/create?template=e053c948-88c8-4bbc-86e8-4bf6f2fd4bab",
        haloHelpLink: "https://w.amazon.com/bin/view/NetworkSpatialData/Halo/UserGuide/",
        haloHelpText: "Halo FAQ/User Guide",
        lighthouseBugLink: "https://t.corp.amazon.com/create/templates/792521b2-a12e-4e0e-a5e6-fb38e3340d5b",
        lighthouseFeatureLink: "https://issues.amazon.com/issues/create?template=ac6d4860-13c4-473a-9b30-de2871be9890",
        lighthouseHelpLink: "https://w.amazon.com/bin/view/NDMS/Lighthouse",
        lighthouseHelpText: "FAQ/User Guide"
    };

    static FLASHBAR_STRINGS = {
        flashbarFLUXOError: <span>There was a problem fetching the statuses of the TTs on this order. You may still make changes but will not be able to complete the order. If this error persists, please {<a href="https://t.corp.amazon.com/create/templates/b749d140-bf14-46ee-8ca0-b92cafa591b8" target="_blank" rel="noopener noreferrer">submit a ticket.</a>}</span>,
        flashbarTimeoutErrorText: <span>The request to the server has timed out, please try again later. If this error persists, please {<a href="https://t.corp.amazon.com/create/templates/b749d140-bf14-46ee-8ca0-b92cafa591b8" target="_blank" rel="noopener noreferrer">submit a ticket.</a>}</span>,
        flashbarNoPermission: <span>Access denied. Only a member of the service&apos;s resolver group may perform this action. If you are seeing this error, please {<a href="https://t.corp.amazon.com/create/templates/b749d140-bf14-46ee-8ca0-b92cafa591b8" target="_blank" rel="noopener noreferrer">submit a ticket.</a>}</span>,
        flashbarMidwayError: "Midway session expired, please reload the page in the browser. Remember to save your " +
            "inputted data!",
        flashbarInvalidInput: "Please correct all errors before submitting the form.",
        flashbarSuccessText: "Request Successful!",
        flashbarNoCircuitDesigns: "No circuits currently exist in Fremont. Please create a circuit first.",
        flashbarNoMatchingCircuits: "There are no active circuits who match the selected contract central URL" +
            " and span number.",
        flashbarNoProviders: "No providers currently exist in Fremont. Please create a provider first.",
        flashbarNoSites: "No sites currently exist in Fremont. Please create a site first.",
        flashbarErrorChangingDisplayMode: <span>An error occurred changing the display mode. If you are seeing this error, try refreshing the page. If the error persists, please {<a href="https://t.corp.amazon.com/create/templates/b749d140-bf14-46ee-8ca0-b92cafa591b8" target="_blank" rel="noopener noreferrer">submit a ticket.</a>}</span>,
        flashbarErrorLoadingComponentData: <span>An error occurred loading component data. If you are seeing this error, try refreshing the page. If the error persists, please {<a href="https://t.corp.amazon.com/create/templates/b749d140-bf14-46ee-8ca0-b92cafa591b8" target="_blank" rel="noopener noreferrer">submit a ticket.</a>}</span>,
        flashbarErrorLoadingSiteComponentData: <span>An error occurred loading site component data. If you are seeing this error, try refreshing the page. If the error persists, please {<a href="https://t.corp.amazon.com/create/templates/b749d140-bf14-46ee-8ca0-b92cafa591b8" target="_blank" rel="noopener noreferrer">submit a ticket.</a>}</span>,
        flashbarErrorLoadingNodeComponentData: <span>An error occurred loading node component data. If you are seeing this error, try refreshing the page. If the error persists, please {<a href="https://t.corp.amazon.com/create/templates/b749d140-bf14-46ee-8ca0-b92cafa591b8" target="_blank" rel="noopener noreferrer">submit a ticket.</a>}</span>,
        flashbarErrorLoadingPortComponentData: <span>An error occurred loading port component data. If you are seeing this error, try refreshing the page. If the error persists, please {<a href="https://t.corp.amazon.com/create/templates/b749d140-bf14-46ee-8ca0-b92cafa591b8" target="_blank" rel="noopener noreferrer">submit a ticket.</a>}</span>,
        flashbarErrorLoadingLagComponentData: <span>An error occurred loading lag component data. If you are seeing this error, try refreshing the page. If the error persists, please {<a href="https://t.corp.amazon.com/create/templates/b749d140-bf14-46ee-8ca0-b92cafa591b8" target="_blank" rel="noopener noreferrer">submit a ticket.</a>}</span>,
        flashbarErrorLoadingUnitComponentData: <span>An error occurred loading unit component data. If you are seeing this error, try refreshing the page. If the error persists, please {<a href="https://t.corp.amazon.com/create/templates/b749d140-bf14-46ee-8ca0-b92cafa591b8" target="_blank" rel="noopener noreferrer">submit a ticket.</a>}</span>,
        flashbarErrorSubmittingOrder: <span>An error occurred submitting order data. If you are seeing this error, try refreshing the page. If the error persists, please {<a href="https://t.corp.amazon.com/create/templates/b749d140-bf14-46ee-8ca0-b92cafa591b8" target="_blank" rel="noopener noreferrer">submit a ticket.</a>}</span>,
        flashbarErrorResolvingBlocker: <span>An error occurred resolving your blocker. If you are seeing this error, try refreshing the page. If the error persists, please {<a href="https://t.corp.amazon.com/create/templates/b749d140-bf14-46ee-8ca0-b92cafa591b8" target="_blank" rel="noopener noreferrer">submit a ticket.</a>}</span>,
        flashbarErrorRetrievingCircuitDesigns: <span>An error occurred retrieving circuit data from the server. If you are seeing this error, try refreshing the page. If the error persists, please {<a href="https://t.corp.amazon.com/create/templates/b749d140-bf14-46ee-8ca0-b92cafa591b8" target="_blank" rel="noopener noreferrer">submit a ticket.</a>}</span>,
        flashbarErrorRetrievingProviders: <span>An error occurred retrieving provider data from the server. If you are seeing this error, try refreshing the page. If the error persists, please {<a href="https://t.corp.amazon.com/create/templates/b749d140-bf14-46ee-8ca0-b92cafa591b8" target="_blank" rel="noopener noreferrer">submit a ticket.</a>}</span>,
        flashbarErrorRetrievingSites: <span>An error occurred retrieving site data from the server. If you are seeing this error, try refreshing the page. If the error persists, please {<a href="https://t.corp.amazon.com/create/templates/b749d140-bf14-46ee-8ca0-b92cafa591b8" target="_blank" rel="noopener noreferrer">submit a ticket.</a>}</span>,
        flashbarErrorRetrievingTags: <span>An error occurred retrieving tag data from the server. If you are seeing this error, try refreshing the page. If the error persists, please {<a href="https://t.corp.amazon.com/create/templates/b749d140-bf14-46ee-8ca0-b92cafa591b8" target="_blank" rel="noopener noreferrer">submit a ticket.</a>}</span>
    };

    static FLASHBAR_TYPES = {
        success: "success",
        error: "error",
        info: "info",
        warning: "warning"
    };

    static COMPLETION_DATE_TABLE_INSTALL = [
        {
            label: Constants.SERVICE_TYPES.BACKBONE,
            daysTable: {
                createCircuits: "20",
                submitForApproval: "21",
                orderAcceptance: "31",
                portReservation: "167",
                cabling: "266",
                troubleshooting: "278",
                implementation: "286",
                ipAllocation: "278",
                median: "286",
                backboneImplementation: "31"
            }
        },
        {
            label: Constants.SERVICE_TYPES.SFP,
            daysTable: {
                createCircuits: "20",
                submitForApproval: "21",
                orderAcceptance: "31",
                portReservation: "69",
                cabling: "118",
                troubleshooting: "135",
                ipAllocation: "135",
                implementation: "159",
                median: "159"
            }
        },
        {
            label: Constants.SERVICE_TYPES.PAID_PEERING,
            daysTable: {
                createCircuits: "20",
                submitForApproval: "21",
                orderAcceptance: "31",
                portReservation: "162",
                cabling: "206",
                troubleshooting: "217",
                ipAllocation: "217",
                implementation: "240",
                median: "240"
            }
        },
        {
            label: Constants.SERVICE_TYPES.IP_TRANSIT,
            daysTable: {
                createCircuits: "20",
                submitForApproval: "21",
                orderAcceptance: "31",
                portReservation: "52",
                cabling: "104",
                troubleshooting: "113",
                ipAllocation: "113",
                implementation: "134",
                median: "134"
            }
        },
        {
            label: Constants.SERVICE_TYPES.INTERNET_EXCHANGE,
            daysTable: {
                createCircuits: "20",
                submitForApproval: "21",
                orderAcceptance: "31",
                portReservation: "100",
                cabling: "200",
                troubleshooting: "202",
                ipAllocation: "202",
                implementation: "233",
                median: "233"
            }
        }
    ];

    // TODO: currently only SFP and Backbone values are correct
    static COMPLETION_DATE_TABLE_DECOM = [
        {
            label: Constants.SERVICE_TYPES.SFP,
            daysTable: {
                createCircuits: "37",
                submitForApproval: "38",
                orderAcceptance: "53",
                filter: "225",
                turnDown: "279",
                cabling: "622",
                releasePort: "685",
                carrierNotification: "1017",
                carrierConfirmation: "1018",
                median: "1018"
            }
        },
        {
            label: Constants.SERVICE_TYPES.BACKBONE,
            daysTable: {
                createCircuits: "37",
                submitForApproval: "38",
                orderAcceptance: "53",
                costOut: "119",
                turnDown: "120",
                cabling: "219",
                releasePort: "282",
                carrierNotification: "302",
                carrierConfirmation: "309",
                verifyStopBilling: "310",
                median: "310"
            }
        },
        {
            label: Constants.SERVICE_TYPES.PAID_PEERING,
            daysTable: {
                createCircuits: "37",
                submitForApproval: "38",
                orderAcceptance: "53",
                filter: "225",
                turnDown: "279",
                cabling: "622",
                releasePort: "685",
                carrierNotification: "1017",
                carrierConfirmation: "1018",
                median: "1018"
            }
        },
        {
            label: Constants.SERVICE_TYPES.IP_TRANSIT,
            daysTable: {
                createCircuits: "37",
                submitForApproval: "38",
                orderAcceptance: "53",
                filter: "225",
                turnDown: "279",
                cabling: "622",
                releasePort: "685",
                carrierNotification: "1017",
                carrierConfirmation: "1018",
                median: "1018"
            }
        },
        {
            label: Constants.SERVICE_TYPES.INTERNET_EXCHANGE,
            daysTable: {
                createCircuits: "37",
                submitForApproval: "38",
                orderAcceptance: "53",
                filter: "225",
                turnDown: "279",
                cabling: "622",
                releasePort: "685",
                carrierNotification: "1017",
                carrierConfirmation: "1018",
                median: "1018"
            }
        }
    ];


    static VALIDATION_ERROR_STRINGS = {
        stringLength: "The maximum number of characters for this field is 399,000. Please remove some text.",
        networkSiteClliCode: "Invalid network site CLLI code. A network site CLLI code consists of six letters" +
            " followed by two letters or numbers.",
        networkSupportSiteClliCode: "Invalid network support site CLLI code. A network support site CLLI" +
            " code consists of seven letters followed by four numbers.",
        phone: "Phone number must be at least seven digits.",
        fax: "Fax number must be ten digits, no spaces or punctuation.",
        email: "Invalid email address",
        website: "Invalid website",
        lettersAndSpaces: "May only contain letters and spaces",
        numeric: "May only contains numbers",
        decimal: "May only contain a decimal number",
        alphaNumeric: "May only contain letters and numbers",
        latitude: "Invalid latitude. A valid latitude is any number between -90 and 90.",
        longitude: "Invalid longitude. A valid longitude is any number between -180 and 180.",
        addressLineOneOrTwo: "Invalid address",
        airportCode: "An airport code consists of exactly three capital letters.",
        ttURL: "TT URL must contain t.corp.amazon.com, tt.amazon.com, sim.amazon.com, or issues.amazon.com",
        ipv4: "Invalid IPv4 address.",
        ipv6: "Invalid IPv6 address.",
        topologyMapPositionGap: "Gap in topology map",
        topologyMapMissingSiteId: "Site ID missing",
        topologyMapCircuitQuantityIncorrect: "Circuit quantity for each position must equal"
            + " the number of circuits on the path order",
        topologyMapOrderDoesNotExist: "Order ID does not exist in Fremont",
        topologyMapOrderNotValidated: "Order ID has not been validated with Fremont",
        topologyCustomerFabricMissing: "Please select a topology customer fabric",
        topologySourceSystemMissing: "Please select a source",
        opticalTopologyMapProjectTypeMissing: "Please select a project type to create span order"
    };

    static LIGHTHOUSE_PREPENDED_PATHS = {
        fremont: "/fremont",
        halo: "/halo",
        lighthouse: "/lighthouse",
        linkservice: "/linkservice"
    }

    static ROUTES = {
        asn: `${Constants.LIGHTHOUSE_PREPENDED_PATHS.fremont}/asn`,
        circuitDesign: `${Constants.LIGHTHOUSE_PREPENDED_PATHS.fremont}/circuitDesign`,
        contact: `${Constants.LIGHTHOUSE_PREPENDED_PATHS.fremont}/contact`,
        createAsn: `${Constants.LIGHTHOUSE_PREPENDED_PATHS.fremont}/createAsn`,
        createContact: `${Constants.LIGHTHOUSE_PREPENDED_PATHS.fremont}/createContact`,
        createOrder: `${Constants.LIGHTHOUSE_PREPENDED_PATHS.fremont}/createOrder`,
        createProvider: `${Constants.LIGHTHOUSE_PREPENDED_PATHS.fremont}/createProvider`,
        createProviderService: `${Constants.LIGHTHOUSE_PREPENDED_PATHS.fremont}/createProviderService`,
        createSite: `${Constants.LIGHTHOUSE_PREPENDED_PATHS.fremont}/createSite`,
        fremontDashboard: Constants.LIGHTHOUSE_PREPENDED_PATHS.fremont,
        manageOrderAssignment: `${Constants.LIGHTHOUSE_PREPENDED_PATHS.fremont}/manageOrderAssignment`,
        stageSla: `${Constants.LIGHTHOUSE_PREPENDED_PATHS.fremont}/stageSla`,
        order: `${Constants.LIGHTHOUSE_PREPENDED_PATHS.fremont}/order`,
        provider: `${Constants.LIGHTHOUSE_PREPENDED_PATHS.fremont}/provider`,
        providerService: `${Constants.LIGHTHOUSE_PREPENDED_PATHS.fremont}/providerService`,
        search: `${Constants.LIGHTHOUSE_PREPENDED_PATHS.fremont}/search`,
        site: `${Constants.LIGHTHOUSE_PREPENDED_PATHS.fremont}/site`,
        operations: `${Constants.LIGHTHOUSE_PREPENDED_PATHS.fremont}/operations`
    };

    /**
     * TODO: [ProjectMango] Should remove after Mango static website infrastructure is up
     * */
    static MANGO_ROUTES = {
        cutsheetUpload: `${Constants.LIGHTHOUSE_PREPENDED_PATHS.linkservice}/cutsheet`,
        linkSearch: `${Constants.LIGHTHOUSE_PREPENDED_PATHS.linkservice}/linkSearch`,
        linkDetails: `${Constants.LIGHTHOUSE_PREPENDED_PATHS.linkservice}/linkDetails`,
        linkVisualization: `${Constants.LIGHTHOUSE_PREPENDED_PATHS.linkservice}/linkVisualization`,
        linkServiceDashboard: Constants.LIGHTHOUSE_PREPENDED_PATHS.linkservice
    }

    static LIGHTHOUSE_ROUTES = {
        cablingCutsheet: `${Constants.LIGHTHOUSE_PREPENDED_PATHS.lighthouse}/cutsheet`,
        lighthouseLandingPage: "/",
        ispPatchPanelManagement: `${Constants.LIGHTHOUSE_PREPENDED_PATHS.lighthouse}/patchpanels`,
        reportFiber: `${Constants.LIGHTHOUSE_PREPENDED_PATHS.lighthouse}/reportFiber`,
        ispDashboard: `${Constants.LIGHTHOUSE_PREPENDED_PATHS.lighthouse}/isp`
    }

    static NCIS_ROUTES = {
        order: "https://awsige.lightning.force.com/lightning/r/Order__c",
        circuit: "https://awsige.lightning.force.com/lightning/r/Vendor_Circuit__c",
        view: "view"
    };

    static TOOLTIP_STRINGS = {
        asnExplanation: "ASN information, inputted by the user.",
        asnNumberEditExplanation: "Autonomous System Number is a non editable field.",
        serviceTypeEditExplanation: "Service type is a non editable field.",
        circuitStatusExplanation: "Only circuits that have no components can be removed from an order.",
        clusterEditExplanation: "Cluster is determined based off of site name and is non editable.",
        consumedByCircuitIdForNewRevisionExplanation: "This circuit consumes the circuit whose detail page we are"
            + " currently on inside of a change order. This means that when the change order completes, this"
            + " consuming circuit will become the Consumed by Circuit.",
        consumedByCircuitIdExplanation: "This circuit consumes the circuit whose detail page we are currently on.",
        consumingCircuitIdListExplanation: "These circuits are consumed by the circuit whose detail page we are on." +
            " These circuits can also be seen in the details tab below.",
        consumedByCircuitIdRemovalExplanation: "Only circuits that are not consumed can be removed.",
        contactExplanation: "Contact information, inputted by the user.",
        countriesSupported: "Click here for an explanation of the countries supported term used in Fremont.",
        lacpProviderChangeOrderExplanation: "LACP provider determines whether or not circuits will have lags."
            + " If LACP provider is Yes, the circuits will have lags. If LACP provider is No, the circuits will"
            + " not have lags. To remove or add lags from your chosen circuits as a part of this change order,"
            + " modify the LACP provider field appropriately.",
        latitudeFieldExplanation: "A valid latitude is any number between -90 and 90.",
        longitudeFieldExplanation: "A valid longitude is any number between -180 and 180.",
        geographicRegionFieldExplanation: "This field is auto-populated. No user input is required.",
        marketFieldExplanation: "Market is a three character airport code.",
        networkSupportSiteClliCodeFieldExplanation: "A network support site CLLI code consists of seven letters" +
            " followed by four numbers.",
        networkSiteClliCodeFieldExplanation: "A network site CLLI code consists of six letters followed by two" +
            " letters or numbers.",
        orderExplanation: "Order information, inputted by the user.",
        orderTypeEditExplanation: "Order type is a non editable field.",
        pathNameFieldExplanation: "The circuits on this order have different path names." +
            " Updating the path name in this stage will update the path" +
            " for all circuits on this order.",
        providerDisabledExplanation: "An error occurred retrieving provider data from the server.",
        providerCountryEditExplanation: "Country is determined based of site information and is non editable.",
        providerExplanation: "Provider information, inputted by the user.",
        providerNameEditExplanation: "Provider Name is non editable field.",
        providerServiceExplanation: "Provider service information, inputted by the user.",
        regionsSupported: "Click here for an explanation of the regions supported term used in Fremont.",
        resourceTableHover: "Hover mouse over cell to enable drop down menu",
        serviceEditExplanation: "Service is a non editable field.",
        siteExplanation: "Site information, inputted by the user.",
        sitesSupported: "Click here for an explanation of the sites supported term used in Fremont.",
        supportAll: "Click here for an explanation of the support all term used in Fremont.",
        tagOrderAssociation: "Creating a tag from this order will automatically associate the tag with the order."
    };

    static TOOLTIP_LINKS = {
        countriesSupported: "https://w.amazon.com/bin/view/Networking/Fremont/FAQ/#HWhatdoesCountriesSupportedmeaninFremont3F",
        regionsSupported: "https://w.amazon.com/bin/view/Networking/Fremont/FAQ/#HWhatdoesRegionsSupportedmeaninFremont3F",
        sitesSupported: "https://w.amazon.com/bin/view/Networking/Fremont/FAQ/#HWhatdoesSitesSupportedmeaninFremont3F",
        supportAll: "https://w.amazon.com/bin/view/Networking/Fremont/FAQ/#HWhatdoesSupportsAllmeaninFremont3F"
    };

    static ADDRESS_IDS = {
        title: "addressTitle",
        lineOne: "addressLineOne",
        lineTwo: "addressLineTwo",
        city: "addressCity",
        county: "addressCounty",
        state: "addressState",
        zipCode: "addressZipCode",
        country: "addressCountry",
        latitude: "addressLatitude",
        longitude: "addressLongitude"
    };

    static CONTENT_TYPE_KMZ = "application/vnd.google-earth.kmz";
    static CONTENT_TYPE_KML = "application/vnd.google-earth.kml+xml";

    static VALID_ATTACHMENT_CONTENT_TYPES = [
        // Text-based files
        "application/pdf", "text/plain", "text/csv",

        // Microsoft Office Products
        "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-powerpoint", "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/vnd.ms-outlook",

        // Images
        "image/gif", "image/jpeg", "image/png",

        // Geospatial
        Constants.CONTENT_TYPE_KML, Constants.CONTENT_TYPE_KMZ
    ];

    static EXCLUDED_CURRENCIES = [
        // Excluded because we manually add it later
        "USD",
        // Excluded because unsure if it is actual currency (name is also very un-friendly to user)
        "USS",
        // Excluded because it is reserved for testing purposes
        "XTS",
        // Excluded because its name is "No currency" and we already display a "None" option to the user
        "XXX"
    ];

    static GEOGRAPHIC_REGIONS = {
        AMER: "AMER",
        APAC: "APAC",
        EMEA: "EMEA"
    };

    static ORDER_PRIORITY_OPTIONS = {
        P0: "P0",
        P1: "P1",
        P2: "P2",
        P3: "P3"
    };

    static ORDER_REGIONS = {
        TRANS_PACIFIC: "Trans-Pacific",
        TRANS_ATLANTIC: "Trans-Atlantic",
        EMEA_APAC: "EMEA-APAC"
    };

    static ORDER_REGION_MAP_BASED_ON_GEOGRAPHIC_REGIONS = {
        [Constants.GEOGRAPHIC_REGIONS.AMER]: {
            [Constants.GEOGRAPHIC_REGIONS.AMER]: Constants.GEOGRAPHIC_REGIONS.AMER,
            [Constants.GEOGRAPHIC_REGIONS.APAC]: Constants.ORDER_REGIONS.TRANS_PACIFIC,
            [Constants.GEOGRAPHIC_REGIONS.EMEA]: Constants.ORDER_REGIONS.TRANS_ATLANTIC
        },
        [Constants.GEOGRAPHIC_REGIONS.APAC]: {
            [Constants.GEOGRAPHIC_REGIONS.APAC]: Constants.GEOGRAPHIC_REGIONS.APAC,
            [Constants.GEOGRAPHIC_REGIONS.AMER]: Constants.ORDER_REGIONS.TRANS_PACIFIC,
            [Constants.GEOGRAPHIC_REGIONS.EMEA]: Constants.ORDER_REGIONS.EMEA_APAC
        },
        [Constants.GEOGRAPHIC_REGIONS.EMEA]: {
            [Constants.GEOGRAPHIC_REGIONS.EMEA]: Constants.GEOGRAPHIC_REGIONS.EMEA,
            [Constants.GEOGRAPHIC_REGIONS.AMER]: Constants.ORDER_REGIONS.TRANS_ATLANTIC,
            [Constants.GEOGRAPHIC_REGIONS.APAC]: Constants.ORDER_REGIONS.EMEA_APAC
        }
    };

    static ASN_SESSION_TYPES = [
        {
            label: "None",
            value: ""
        },
        {
            label: "Transit",
            value: "Transit"
        },
        {
            label: "Partial Transit",
            value: "Partial Transit"
        },
        {
            label: "Tier 1",
            value: "Tier 1"
        },
        {
            label: "Private Peering",
            value: "Private Peering"
        },
        {
            label: "Static Transit",
            value: "Static Transit"
        }
    ];

    static GENERAL_STATUS_OPTIONS = [
        {
            label: Constants.STATUS.active,
            value: Constants.STATUS.active
        },
        {
            label: Constants.STATUS.inactive,
            value: Constants.STATUS.inactive
        }
    ];

    static TROUBLESHOOTING_STATUS_OPTIONS = [
        {
            label: Constants.STATUS.incomplete,
            value: Constants.STATUS.incomplete
        },
        {
            label: Constants.STATUS.testing,
            value: Constants.STATUS.testing
        },
        {
            label: Constants.STATUS.ready,
            value: Constants.STATUS.ready
        },
        {
            label: Constants.STATUS.pendingPeer,
            value: Constants.STATUS.pendingPeer
        },
        {
            label: Constants.STATUS.pendingDCO,
            value: Constants.STATUS.pendingDCO
        },
        {
            label: Constants.STATUS.success,
            value: Constants.STATUS.success
        }
    ];

    static FIBER_TYPE_OPTIONS = [
        {
            label: "SMF",
            value: "SMF"
        },
        {
            label: "MMF",
            value: "MMF"
        }
    ];

    static CONNECTOR_TYPE_OPTIONS = [
        {
            label: "MPO",
            value: "MPO"
        },
        {
            label: "LCM",
            value: "LCM"
        },
        {
            label: "MTP",
            value: "MTP"
        }
    ];

    static IMPLEMENTATION_STATUS_OPTIONS = [
        {
            label: Constants.STATUS.incomplete,
            value: Constants.STATUS.incomplete
        },
        {
            label: Constants.STATUS.implementation,
            value: Constants.STATUS.implementation
        },
        {
            label: Constants.STATUS.ready,
            value: Constants.STATUS.ready
        },
        {
            label: Constants.STATUS.success,
            value: Constants.STATUS.success
        }
    ];

    static COMPLETION_OPTIONS = [
        {
            label: Constants.STATUS.incomplete,
            value: Constants.STATUS.incomplete
        },
        {
            label: Constants.STATUS.complete,
            value: Constants.STATUS.complete
        }
    ];

    static SUB_STATUS_OPTIONS = {
        portReservation: ["CapEng Review", "Port Availability"],
        cabling: ["CapProv Review", "Continuity Test-Hardloop", "DCO/Colo Request", "Missing Optic", "Pending LOA",
            "Pending New POP Readiness", "Pending Normalizing Loop"],
        troubleshooting: ["CapProv Review", "DCO/Colo Request", "Failed Deployment", "Flapping", "Interface Errors",
            "Light Level Testing", "Pending ILS", "Pending LACP", "Pending LACP - AWS", "Pending LACP - Peer",
            "Pending Mutal Vendor Meeet", "Pending Provider", "Pending Provider Equipment/Upgrade",
            "Pending Provider Patching", "Provider Ordering XC"
        ],
        implementation: ["CapEng Review", "Pending Peer", "Turnup", "UnFilter"]
    };

    static TT_STATUS_OPTIONS = ["Resolved", "Closed", "Pending", "Work in Progress", "Assigned"];
    static SIM_STATUS_OPTIONS = ["Resolved", "Open"];

    static CIRCUIT_BANDWIDTH_OPTIONS = [
        "0.1",
        "1",
        "10",
        "100",
        "400"
    ];

    static CIRCUIT_MAX_QUANTITY = 500;

    static CIRCUIT_LEVEL_BLOCKER_JEP_CODES = [
        "Block if Before or on RCD",
        "CapEng Review",
        "DCO/Colo Request",
        "Interface Status Check",
        "KMZ and SRLG",
        "Light Level Check",
        "Missing Optic",
        "Neighbor Check",
        "Normalize",
        "Pending DCO/Tech Dispatch",
        "Pending External Action - CI",
        "Pending External Action - IC",
        "Pending External Action - TS",
        "Pending LOA",
        "Pending Local Loop",
        "Pending Optical Cut-Sheet",
        "Pending Structured Cabling / MMR PP",
        "Ports/Hardware Availability",
        "Ports Assigned",
        "RFC/BERT Testing",
        "Striping",
        "Turn Up",
        "Verification Completed",
        "XC Completed"
    ];

    static ORDER_LEVEL_BLOCKER_JEP_CODES = [
        "Andon Cord",
        "AriadneTunnelsPending",
        "BizDev Review",
        "CapProv Review",
        "CloudFront Routing",
        "Congestion Management",
        "ConveyorMigrationAndonCord",
        "Co-ordinated Deployment Required",
        "DCOcablingWorkPending",
        "DCOSchedulingBlocked",
        "DestinationUnfilterPending",
        "Disconnect Pending Install",
        "FNC Migration",
        "Fremont Encryption Type Not Present",
        "IP Testing Required",
        "Legal Approvals",
        "MCMCreationPending",
        "MCMWindowPending",
        "MigrationBlocked",
        "Negotiating Commercial Terms",
        "No Ports Due To Striping",
        "Path ID Not Present",
        "Peer Backhaul Capacity (long-term)",
        "Peer Backhaul Capacity (short-term)",
        "Peer Does Not Have Ports",
        "Peer Hardware Scaling (long-term)",
        "Peer Hardware Scaling (short-term)",
        "Pending AMZN New Site Readiness",
        "Pending Colo PO",
        "Pending MD5 Key",
        "Port/Hardware Availability A Client Side",
        "Port/Hardware Availability Knox A Side",
        "Port/Hardware Availability Knox Z Side",
        "Port/Hardware Availability Z Client Side",
        "RED/No Known Path Forward",
        "SourceCleanupPending",
        "SourceKreugerUnshutPending",
        "SourceKruegerShutPending",
        "SourcePtshiftAwayPending",
        "SourcePtshiftBackPending",
        "SPC Migration",
        "Submitted",
        "Supplier Name ID Missing",
        "Telco LOA",
        "Telco RFP",
        "Underutilized Elsewhere"
    ];

    static GENERAL_COMPLETION_OPTIONS = [
        {
            label: Constants.INCOMPLETE,
            value: Constants.INCOMPLETE
        },
        {
            value: Constants.COMPLETE
        }
    ];

    static IP_DISPOSITION_OPTIONS = [
        {
            label: "Amazon to Provide",
            value: "Amazon to Provide"
        },
        {
            label: "No one to Provide",
            value: "No one to Provide"
        },
        {
            label: "Provider to Provide",
            value: "Provider to Provide"
        }
    ];

    static LOA_DISPOSITION_OPTIONS = [
        {
            label: Constants.LOA_DISPOSITIONS.amazon,
            value: Constants.LOA_DISPOSITIONS.amazon
        },
        {
            label: Constants.LOA_DISPOSITIONS.provider,
            value: Constants.LOA_DISPOSITIONS.provider
        }
    ];

    // Describe a map of the port size plus the option number (401 is the first option for a port size of 400, etc.)
    // to the appropriate link handoff value
    static LINK_HANDOFF_VALUES = {
        0.11: "SMF 1000BASE-LX/LH SFP (10km)",
        0.12: "Copper 10/100/1000Base-T SFP",
        11: "1G",
        12: "SMF 1000BASE-LX/LH SFP (10km)",
        13: "Copper 10/100/1000Base-T SFP",
        101: "10G, SFP+, LR",
        102: "10G, SFP+, LR Lite(1.5m)",
        103: "10G, SFP+, SR",
        401: "40G, QSFP+, AOC",
        402: "40G, QSFP+, ESR4 (300m)",
        403: "40G, QSFP+, ER4 (40km)",
        404: "40G, QSFP+, LR4 (2km)",
        405: "40G, QSFP+, LR4 (10km)",
        406: "40G, QSFP+, PSM4 (10km)",
        407: "40G, QSFP+, PSM4 Lite (2km)",
        408: "40G, QSFP+, SR4",
        1001: "100G, AOC",
        1002: "100G, PSM4",
        1003: "100G, QSFP28, CWDM4 (2km)",
        1004: "100G, QSFP28, CWDM4 (10km)",
        1005: "100G, QSFP28, DR1 (500M)",
        1006: "100G, QSFP28, LR4 (10km)",
        1007: "100G, QSFP56-DD DR4",
        4001: "400G, QSFP56, DR4",
        4002: "400G, QSFP56, FR4 (2km)",
        4003: "400G, QSFP56, LR4 (10km)",
        4004: "400G, QSFP56-DD DR4"
    }

    // Here we define a map of portSizes to the default Link Handoff
    // value specified in  https://issues.amazon.com/issues/FremontNEST-789
    static LINK_HANDOFF_DEFAULTS = {
        0.1: Constants.LINK_HANDOFF_VALUES[0.11],
        1: Constants.LINK_HANDOFF_VALUES[11],
        10: Constants.LINK_HANDOFF_VALUES[101],
        40: Constants.LINK_HANDOFF_VALUES[405],
        100: Constants.LINK_HANDOFF_VALUES[1006],
        400: Constants.LINK_HANDOFF_VALUES[4001]
    }

    static LINK_HANDOFF_OPTIONS = {
        0.1: [
            Constants.LINK_HANDOFF_VALUES[0.11],
            Constants.LINK_HANDOFF_VALUES[0.12],
            Constants.OTHER
        ],
        1: [
            Constants.LINK_HANDOFF_VALUES[11],
            Constants.LINK_HANDOFF_VALUES[12],
            Constants.LINK_HANDOFF_VALUES[13],
            Constants.OTHER
        ],
        10: [
            Constants.LINK_HANDOFF_VALUES[101],
            Constants.LINK_HANDOFF_VALUES[102],
            Constants.LINK_HANDOFF_VALUES[103],
            Constants.OTHER
        ],
        40: [
            Constants.LINK_HANDOFF_VALUES[401],
            Constants.LINK_HANDOFF_VALUES[402],
            Constants.LINK_HANDOFF_VALUES[403],
            Constants.LINK_HANDOFF_VALUES[404],
            Constants.LINK_HANDOFF_VALUES[405],
            Constants.LINK_HANDOFF_VALUES[406],
            Constants.LINK_HANDOFF_VALUES[407],
            Constants.LINK_HANDOFF_VALUES[408],
            Constants.OTHER
        ],
        100: [
            Constants.LINK_HANDOFF_VALUES[1001],
            Constants.LINK_HANDOFF_VALUES[1002],
            Constants.LINK_HANDOFF_VALUES[1003],
            Constants.LINK_HANDOFF_VALUES[1004],
            Constants.LINK_HANDOFF_VALUES[1005],
            Constants.LINK_HANDOFF_VALUES[1006],
            Constants.LINK_HANDOFF_VALUES[1007],
            Constants.OTHER
        ],
        400: [
            Constants.LINK_HANDOFF_VALUES[4001],
            Constants.LINK_HANDOFF_VALUES[4002],
            Constants.LINK_HANDOFF_VALUES[4003],
            Constants.LINK_HANDOFF_VALUES[4004],
            Constants.OTHER
        ]
    }

    static SPAN_TYPE_OPTIONS = [
        "LONG_HAUL",
        "METRO",
        "SUBSEA",
        "SUBSEA_BACKHAUL"
    ];

    static TRANSPORT_REGION_OPTIONS = [
        "NASA",
        "EMEAI",
        "APAC"
    ];

    static TRANSPORT_CORRIDOR_NASA_OPTIONS = [
        "LatAm",
        "NA-LatAm",
        "North America",
        "Trans-Atlantic"
    ];

    static TRANSPORT_CORRIDOR_EMEAI_OPTIONS = [
        "Africa",
        "Africa-Europe",
        "Africa-India",
        "Africa-Middle East",
        "Europe",
        "Europe-Asia",
        "India",
        "India-Asia",
        "India-Europe",
        "Middle East",
        "Middle East-Asia",
        "Middle East-India",
        "Middle East-Europe",
        "Trans-Atlantic"
    ];

    static TRANSPORT_CORRIDOR_APAC_OPTIONS = [
        "Asia",
        "Australia",
        "Australia-Asia",
        "Australia-NA",
        "Trans-Pacific"
    ];

    static GENERAL_YES_NO_OPTIONS = [
        {
            label: Constants.YES_NO.yes,
            value: true
        },
        {
            label: Constants.YES_NO.no,
            value: false
        }
    ];

    static PROJECT_TYPE_OPTIONS = [
        {
            label: "Metro New Span",
            value: Constants.PROJECT_TYPES.metroNewSpan
        },
        {
            label: "Long Haul New Span",
            value: Constants.PROJECT_TYPES.longHaulNewSpan
        },
        {
            label: "Trunk Scaling",
            value: Constants.PROJECT_TYPES.trunkScaling
        },
        {
            label: "Client Scaling",
            value: Constants.PROJECT_TYPES.clientScaling
        },
        {
            label: "Decommission Span",
            value: Constants.PROJECT_TYPES.decommissionSpan
        }
    ];

    static SPAN_SERVICE_TYPES = ["Lit Waves", "Dark Fiber"];

    static SUBMISSION_STRINGS = {
        readyToSubmit: "Submit",
        submitInProgress: "Submitting"
    };

    static TRANSIT_TYPE_FULL = "Full";
    static TRANSIT_TYPE_PARTIAL = "Partial"

    static MONTHS = [
        {
            label: "None",
            id: ""
        },
        {
            label: "January",
            id: "January"
        },
        {
            label: "February",
            id: "February"
        },
        {
            label: "March",
            id: "March"
        },
        {
            label: "April",
            id: "April"
        },
        {
            label: "May",
            id: "May"
        },
        {
            label: "June",
            id: "June"
        },
        {
            label: "July",
            id: "July"
        },
        {
            label: "August",
            id: "August"
        },
        {
            label: "September",
            id: "September"
        },
        {
            label: "October",
            id: "October"
        },
        {
            label: "November",
            id: "November"
        },
        {
            label: "December",
            id: "December"
        }
    ];

    static INDUSTRY_OPTIONS =
        [
            Constants.BLANK_OPTION,
            "Aerospace and Defense",
            "Advertising",
            "Agriculture",
            "Apparel",
            "Banking",
            "Biotechnology",
            "Chemicals",
            "Communications",
            "Construction",
            "Consulting",
            "Education",
            "Electronics",
            "Energy",
            "Engineering",
            "Entertainment",
            "Environmental",
            "Finance",
            "Food & Beverage",
            "Government",
            "Healthcare",
            "Hospitality",
            "Insurance",
            "Machinery",
            "Manufacturing",
            "Media",
            "Not For Profit",
            "Other",
            "Recreation",
            "Retail",
            "Shipping",
            "Technology",
            "Telecommunications",
            "Transportation",
            "Utilities"
        ];

    static TRANSIT_TYPES = [Constants.TRANSIT_TYPE_FULL, Constants.TRANSIT_TYPE_PARTIAL];

    static INTERNET_EXCHANGE_NAMES = [
        "1-IX Internet Exchange",
        "36C3-YOLOIXP",
        "48 IX",
        "4b42 Internet Exchange Point",
        "6NGIX",
        "A.IX",
        "AAIX",
        "ABQIX",
        "ACTIX",
        "AIX-BD",
        "AKL-IX (Auckland NZ)",
        "Aloha-IX",
        "Aloha-IX MTU9000",
        "AMR-IX",
        "AMS-IX",
        "AMS-IX BA",
        "AMS-IX Bangkok",
        "AMS-IX Caribbean",
        "AMS-IX Chicago",
        "AMS-IX Hong Kong",
        "AMS-IX India",
        "AMS-IX Mumbai",
        "Angola IXP",
        "angonix",
        "ANI-IX Delhi",
        "ANIX",
        "ANIX - Albanian Neutral Internet eXchange",
        "Any2Chicago",
        "Any2Denver",
        "Any2East",
        "Any2Miami",
        "Any2West",
        "APE",
        "Aperture-IX",
        "APLIX",
        "Aqaba IX",
        "ARIX",
        "ArmIX",
        "Asia Smart IX [BBIX Asia]",
        "Asteroid Amsterdam",
        "Asteroid Mombasa",
        "AuvernIX",
        "AZIX",
        "B-IX",
        "BAIKAL-IX",
        "Balcan-IX",
        "BALT-IX",
        "Baltimore IX",
        "BatamIX",
        "BatamIX Batam",
        "BatamIX Jakarta",
        "BAYANTEL",
        "BBIX Amsterdam",
        "BBIX Chicago",
        "BBIX Dallas",
        "BBIX Hong Kong",
        "BBIX London",
        "BBIX Los Angeles",
        "BBIX Manila",
        "BBIX Marseille",
        "BBIX Miami",
        "BBIX Osaka",
        "BBIX Singapore",
        "BBIX Thailand",
        "BBIX Tokyo",
        "BCIX",
        "BDIX",
        "BDIXP",
        "BDX BTN1",
        "Beirut IX",
        "BelgiumIX",
        "BENIN-IX",
        "BFD-IX",
        "BFIX Bobo-Dioulasso",
        "BFIX Burkina Faso",
        "BFIX Ouagadougou",
        "BGP.Exchange",
        "Bharat IX - Mumbai",
        "BHNIX",
        "BigApe",
        "BiX",
        "BIX Bergen",
        "BIX Denpasar 1",
        "BIX Denpasar 2",
        "BIX Jakarta",
        "BIX Malang",
        "BIX Semarang",
        "BIX Surabaya",
        "BIX.BG",
        "BKNIX",
        "BKS-IX",
        "BNIIX",
        "BNIX",
        "BOR-IX",
        "Borneo-IX",
        "Boston Internet Exchange",
        "BR-IX",
        "BreizhIX",
        "BREM-IX",
        "BrestIX",
        "BTCL IX",
        "btIX",
        "BVIX",
        "BW-IX Karlsruhe",
        "BW-IX Stuttgart / S-IX",
        "BY-IX",
        "BéarnIX",
        "C2IX",
        "CABASE-BHB - IX Argentina (Bahia Blanca)",
        "CABASE-BRC - IX Argentina (Bariloche)",
        "CABASE-BUE - IX Argentina (Buenos Aires)",
        "CABASE-CDU - IX Argentina (Concepcion del Uruguay)",
        "CABASE-COR - IX Argentina (Cordoba)",
        "CABASE-DLC - IX Argentina (Santa Teresita)",
        "CABASE-JUJ - IX Argentina (Jujuy)",
        "CABASE-JUN - IX Argentina (Junin)",
        "CABASE-LPL - IX Argentina (La Plata)",
        "CABASE-MDQ - IX Argentina (Mar del Plata)",
        "CABASE-MZA - IX Argentina (Mendoza)",
        "CABASE-NGB - IX Argentina (Pilar)",
        "CABASE-NQN - IX Argentina (Neuquen)",
        "CABASE-OGB - IX Argentina (Moreno)",
        "CABASE-PER - IX Argentina (Pergamino)",
        "CABASE-PMY - IX Argentina (Puerto Madryn)",
        "CABASE-POS - IX Argentina (Posadas)",
        "CABASE-PPN - IX Argentina",
        "CABASE-RCU - IX Argentina (Rio Cuarto)",
        "CABASE-ROS - IX Argentina (Rosario)",
        "CABASE-RST - IX Argentina (Resistencia)",
        "CABASE-SFE - IX Argentina (Santa Fe)",
        "CABASE-SLT - IX Argentina (Salta)",
        "CABASE-SLU - IX Argentina (San Luis)",
        "CABASE-SZP - IX Argentina (Saenz Peña)",
        "CABASE-TDL - IX Argentina (Tandil)",
        "CABASE-TUC - IX Argentina (Tucuman)",
        "CABASE-UAQ - IX Argentina (San Juan)",
        "CABASE-VDM - IX Argentina (Viedma)",
        "CAIX",
        "CAMIX Douala",
        "CAMIX Yaounde",
        "CAS-IX",
        "CATNIX",
        "CBIX",
        "CDIX",
        "CentralIX",
        "CePIX.pl",
        "CFiX (Central Florida Internet Exchange)",
        "CFLIX (Central Florida Internet Exchange)",
        "CGIX",
        "Charlotte (NC-IX)",
        "Charlotte-IX",
        "CHC-IX (Christchurch NZ)",
        "ChIX",
        "CHIX-CH",
        "CHIX-NZ",
        "CHN-IX Beijing",
        "CHN-IX Guangzhou",
        "CHN-IX Shanghai",
        "CHN-IX Shenzhen",
        "CHN-IX Zhongwei",
        "CINX",
        "CINX Voice",
        "CitranetIX",
        "CIVIX",
        "CIX",
        "CIX Australia",
        "CIX KR",
        "CIX-ATL",
        "CIXP",
        "CLOUD-IX EKT",
        "CLOUD-IX KHA",
        "CLOUD-IX KIEV",
        "CLOUD-IX MSK",
        "CLOUD-IX SPB",
        "CLOUD-IX VRJ",
        "CLOUDXCHANGE - CXC",
        "CMH-IX",
        "CN-IX-BJ",
        "CN-IX-GZ",
        "CN-IX-SH",
        "Cnean",
        "CNI-IX Jakarta",
        "CNIX",
        "CNX",
        "CODIX",
        "COIX",
        "Community-IX",
        "Community-IX Vienna",
        "Community-IX.ch",
        "CON-IX Brisbane",
        "CON-IX Melbourne",
        "CON-IX Sydney",
        "CoreSite - Any2 Boston",
        "CoreSite - Any2 California",
        "CoreSite - Any2 Chicago",
        "CoreSite - Any2 Denver ",
        "CoreSite - Any2 Miami",
        "CoreSite - Any2 New York",
        "CoreSite - Any2 NorthEast",
        "CoreSite - Any2 Silicon Valley",
        "CoreSite - Any2Boston",
        "CoreSite - Any2California",
        "CoreSite - Any2Chicago",
        "CoreSite - Any2Denver",
        "CoreSite - Any2East",
        "CoreSite - Any2Miami",
        "CoreSite - Any2New York",
        "CoreSite - Any2Silicon Valley",
        "Crimea-IX",
        "CRIX",
        "CSIX",
        "CSL Thai-IX Bangkok",
        "CSL Thai-IX Malaysia",
        "CSL Thai-IX Singapore",
        "CyIX",
        "CyrusOne IX Austin",
        "CyrusOne IX Chicago",
        "CyrusOne IX Dallas",
        "CyrusOne IX Houston",
        "CyrusOne IX Phoenix",
        "CyrusOne IX San Antonio",
        "CyrusOne IX Sterling",
        "DACS-IX East",
        "DACS-IX North",
        "DACS-IX South",
        "Dallas-IX",
        "DATAIX",
        "DataLine-IX",
        "DCI-IX",
        "DE-CIX ASEAN",
        "DE-CIX Barcelona",
        "DE-CIX Chennai",
        "DE-CIX Chicago",
        "DE-CIX Dallas",
        "DE-CIX Delhi",
        "DE-CIX Dusseldorf",
        "DE-CIX Frankfurt",
        "DE-CIX Hamburg",
        "DE-CIX Istanbul",
        "DE-CIX Johor Bahru",
        "DE-CIX Kolkata",
        "DE-CIX Kuala Lumpur",
        "DE-CIX Lisbon",
        "DE-CIX Madrid",
        "DE-CIX Malaysia",
        "DE-CIX Marseille",
        "DE-CIX Mumbai",
        "DE-CIX Munich",
        "DE-CIX New York",
        "DE-CIX Palermo",
        "DE-CIX Phoenix",
        "DE-CIX Singapore",
        "Denver IX",
        "DET-IX",
        "Digital Edge EPIX Jakarta",
        "Digital Edge EPIX Philippines",
        "Digital Realty Ashburn",
        "Digital Realty Atlanta",
        "Digital Realty Chicago",
        "Digital Realty Dallas",
        "Digital Realty New York",
        "Digital Realty Phoenix",
        "DINX",
        "DIX",
        "DIX-IE",
        "DjIX",
        "DN-IX",
        "DO-IX",
        "DPE",
        "DRF IX",
        "DSIX",
        "DTEL-IX",
        "DV-IX",
        "Echigo-IX",
        "ECIX-BER",
        "ECIX-DUS",
        "ECIX-FRA",
        "ECIX-HAM",
        "ECIX-MUC",
        "ECIX-MUC / INXS by ecix",
        "EdgeIX - Adelaide",
        "EdgeIX - Brisbane",
        "EdgeIX - Darwin",
        "EdgeIX - Hobart",
        "EdgeIX - Melbourne",
        "EdgeIX - Perth",
        "EdgeIX - Sydney",
        "EG-IX",
        "EPIX.Katowice",
        "EPIX.Warszawa-KIX",
        "Equinix Amsterdam",
        "Equinix Ashburn",
        "Equinix Atlanta",
        "Equinix Barcelona",
        "Equinix Bogota",
        "Equinix Chicago",
        "Equinix Dallas",
        "Equinix Denver",
        "Equinix Dublin",
        "Equinix Frankfurt",
        "Equinix Helsinki",
        "Equinix Hong Kong",
        "Equinix Houston",
        "Equinix Lisbon",
        "Equinix London",
        "Equinix Los Angeles",
        "Equinix Madrid",
        "Equinix Manchester",
        "Equinix Melbourne",
        "Equinix Mexico",
        "Equinix Miami",
        "Equinix Miami IX",
        "Equinix Milan",
        "Equinix Muscat",
        "Equinix New York",
        "Equinix Osaka",
        "Equinix Palo Alto",
        "Equinix Paris",
        "Equinix Perth",
        "Equinix Rio de Janeiro",
        "Equinix San Jose",
        "Equinix Seattle",
        "Equinix Seoul",
        "Equinix Singapore",
        "Equinix Stockholm",
        "Equinix Sydney",
        "Equinix São Paulo",
        "Equinix Tokyo",
        "Equinix Toronto",
        "Equinix Vienna (VA)",
        "Equinix Warsaw",
        "Equinix Zurich",
        "ErIX",
        "ESPANIX Madrid Lower LAN",
        "ESPANIX Madrid Upper LAN",
        "Eurasia Peering IX",
        "EuroGIX",
        "EVIX",
        "Extreme IX Bangalore",
        "Extreme IX Chennai",
        "Extreme IX Delhi",
        "Extreme IX Hyderabad",
        "Extreme IX Kolkata",
        "Extreme IX Mumbai",
        "ExWest",
        "FCIX",
        "FD-IX (Texas-IX)",
        "FD-IX - Iowa",
        "FEZIX",
        "FICIX 1 (Espoo)",
        "FICIX 1 (Espoo) IPv6 MTU 9000",
        "FICIX 2 (Helsinki)",
        "FICIX 2 (Helsinki) IPv6 MTU 9000",
        "FICIX 3 (Oulu)",
        "FICIX 3 (Oulu) IPv6 MTU 9000",
        "Fiji-IXP",
        "FIXO",
        "FL-IX",
        "FR-IX",
        "France-IX Marseille",
        "France-IX Paris",
        "Frys-IX",
        "FSIX",
        "FSIX Shanghai",
        "FURB IX",
        "GABIX",
        "Gavlix",
        "GCIIX",
        "GE-CIX",
        "GeekIX Taoyuan",
        "GeekIX Taoyuan (Jumbo)",
        "GetaFIX",
        "GetaFIX Manila",
        "Gig IX Ashburn",
        "GigaNET",
        "GigaNET Kharkov",
        "GigaNET Kharkov local exchange",
        "GigaNET Kiev",
        "GigaNET Moscow",
        "GigaNET Moscow local exchange",
        "GigaNET Odessa",
        "GigaNET Odessa local exchange",
        "GigaNET Warsaw",
        "GigaNET Warsaw local exchange",
        "GigaPIX - LAN 1",
        "GigaPIX - LAN2",
        "GigaPIX - Oporto",
        "GIX",
        "GIX Gdansk",
        "GIX Gothenburg",
        "GIXA",
        "Global Peer Exchange",
        "Global-IX",
        "GN-IX",
        "GOREX",
        "GPIEX",
        "GR-IX::Athens",
        "GR-IX::Thessaloniki",
        "GraX",
        "GrenoblIX",
        "GREX",
        "Grunix",
        "GTIIX",
        "GTIX",
        "GU-IX",
        "Guyanix",
        "HamroIX",
        "HamroIX-Amsterdam",
        "HamroIX-Rome",
        "Harare IX",
        "HFXIX",
        "HIX",
        "HIX Haiti",
        "HIX-NZ",
        "HKIX",
        "Hopus",
        "HOUIX",
        "Houston-IX",
        "HPT1-IX",
        "HTN-IX",
        "IAIX",
        "ICX Delhi",
        "IF-IX",
        "IIFON IX Kolkata",
        "IIX Israel",
        "IIX Riau",
        "IIX-Bali",
        "IIX-Jabar",
        "IIX-Jakarta",
        "IIX-Jateng",
        "IIX-Jogja",
        "IIX-Lampung",
        "IIX-Surabaya",
        "inet2",
        "INEX Cork",
        "INEX LAN1",
        "INEX LAN2",
        "InterAUS - Adelaide IX",
        "InterAUS - Brisbane IX",
        "InterAUS - Canberra IX",
        "InterAUS - Hobart IX",
        "InterAUS - Melbourne IX",
        "InterAUS - Perth IX",
        "InterAUS - Sydney IX",
        "InteRED Panama",
        "InterLAN",
        "IPNET-IX DELHI",
        "ISTIX",
        "ITI-IX",
        "IX Australia (Adelaide SA)",
        "IX Australia (Brisbane QLD)",
        "IX Australia (Canberra ACT)",
        "IX Australia (Melbourne VIC)",
        "IX Australia (Perth WA)",
        "IX Australia (Sydney NSW)",
        "IX Brighton",
        "IX Leeds",
        "IX Liverpool GRX Peering Exchange (5G)",
        "IX Liverpool Internet of Things LAN",
        "IX Liverpool Mersey",
        "IX Liverpool Titanic",
        "IX Palmas",
        "IX Palmas CDNs",
        "IX-Denver",
        "IX-DO",
        "iX-OKC",
        "IX.br (PTT.br) Aracaju",
        "IX.br (PTT.br) Belo Horizonte",
        "IX.br (PTT.br) Belém",
        "IX.br (PTT.br) Brasília",
        "IX.br (PTT.br) Campina Grande",
        "IX.br (PTT.br) Campinas",
        "IX.br (PTT.br) Campo Grande",
        "IX.br (PTT.br) Cascavel",
        "IX.br (PTT.br) Caxias do Sul",
        "IX.br (PTT.br) Cuiabá",
        "IX.br (PTT.br) Curitiba",
        "IX.br (PTT.br) Florianópolis",
        "IX.br (PTT.br) Fortaleza",
        "IX.br (PTT.br) Foz do Iguaçu",
        "IX.br (PTT.br) Goiânia",
        "IX.br (PTT.br) João Pessoa",
        "IX.br (PTT.br) Lajeado",
        "IX.br (PTT.br) Londrina",
        "IX.br (PTT.br) Maceió",
        "IX.br (PTT.br) Manaus",
        "IX.br (PTT.br) Maringá",
        "IX.br (PTT.br) Natal",
        "IX.br (PTT.br) Porto Alegre",
        "IX.br (PTT.br) Recife",
        "IX.br (PTT.br) Rio de Janeiro",
        "IX.br (PTT.br) Salvador",
        "IX.br (PTT.br) Santa Maria",
        "IX.br (PTT.br) São José do Rio Preto",
        "IX.br (PTT.br) São José dos Campos",
        "IX.br (PTT.br) São Luís",
        "IX.br (PTT.br) São Paulo",
        "IX.br (PTT.br) Teresina",
        "IX.br (PTT.br) Vitória",
        "ix.kw",
        "IX.LODZ.PL",
        "IX42 LAS",
        "IX42 NYC",
        "IX42 SG",
        "IXBradford",
        "IXLeeds",
        "IXP BAT",
        "IXP BEA",
        "IXP Ecuador - MEC",
        "IXP Ecuador - STD",
        "IXP FRE",
        "IXP HEL",
        "IXP INFOCOM",
        "IXP LEN",
        "IXP LON",
        "IXP Namibia",
        "IXP NUR",
        "IXP STR",
        "IXP SYD",
        "IXP-GUINEE",
        "IXP-HN",
        "IXP.mk",
        "IXP.MX - Mexico City",
        "IXP.MX Querétaro",
        "IXP.MX Tultitlan",
        "IXPlay Peers",
        "IXPMOE-ZUH",
        "IXPN Abuja",
        "IXPN Kano",
        "IXPN Lagos",
        "IXPN Port Harcourt",
        "IXpy",
        "IXRR Internet Exchange Roraima",
        "IXSY",
        "JBIX",
        "JEDIX",
        "JinIX",
        "JINX",
        "JINX Voice",
        "JKT-IX",
        "JPIX OSAKA",
        "JPIX TOKYO",
        "JPNAP Fukuoka",
        "JPNAP Osaka",
        "JPNAP Tokyo",
        "JTIX",
        "JumboIX Lima",
        "JXIX",
        "KA-NIX",
        "KAZ-GOV-IX",
        "KAZ-IX",
        "KazNIX",
        "KCIX",
        "KG-IX Bishkek",
        "Kherson Traffic Exchange",
        "KINIX",
        "KINX",
        "KIVIX",
        "KIXP - Mombasa",
        "KIXP - Mombasa icolo",
        "KIXP - Nairobi",
        "KIXP - Nairobi icolo",
        "KleyReX",
        "KLIX",
        "KM-IX",
        "Kolkata IX",
        "kremen-IX",
        "KRIX(sejong)",
        "KRS-IX",
        "LBIX",
        "Lillix",
        "LINX Cardiff",
        "LINX LON1",
        "LINX LON1 Multicast",
        "LINX LON2",
        "LINX Manchester",
        "LINX NoVA",
        "LINX Scotland",
        "LINX Wales",
        "Linxdatacenter-IX",
        "LIPTAM",
        "Litix",
        "LIX",
        "LIX-LV",
        "LIXP",
        "LL-IX",
        "LNK-IX",
        "LocIX Dusseldorf",
        "LocIX Frankfurt",
        "LocIX Netherlands",
        "LONAP",
        "LONAP Multicast",
        "LSIX",
        "LSY.CN Amsterdam",
        "LSY.CN Changhua",
        "LSY.CN Hong Kong",
        "LSY.CN Los Angeles",
        "LSY.CN Nanjing",
        "LSY.CN Shenzhen",
        "LSY.CN Tokyo",
        "LSY.CN Wuhan",
        "LU-CIX",
        "LUBIX",
        "LuIXP",
        "LVIV-IX",
        "LyonIX",
        "M-IX",
        "MadIX",
        "MAE Central",
        "MAE East",
        "MAE West",
        "MalmIX Malmo / IXOR",
        "Manama IX",
        "Manila IX",
        "ManxIX",
        "MARIIX",
        "MARTINIX",
        "MASS-IX",
        "Matrix Cable Internet Exchange (MCIX)",
        "MBIX",
        "MBT-IX",
        "MCIX - Matrix Networks",
        "MD-IX",
        "MegaFon-IX",
        "MegaIX Ashburn",
        "MegaIX Auckland",
        "MegaIX Brisbane",
        "MegaIX Dallas",
        "MegaIX Las Vegas",
        "MegaIX Los Angeles",
        "MegaIX Melbourne",
        "MegaIX Perth",
        "MegaIX Seattle",
        "MegaIX Singapore",
        "MegaIX Sofia",
        "MegaIX Sydney",
        "MegaIX Toronto",
        "MekongIX",
        "MESH-IX",
        "MEX-IX",
        "MGIX",
        "MGMix Montgomery",
        "MHK-IX",
        "MiamiIX",
        "MICE",
        "MidWest-IX - Cleveland",
        "MidWest-IX - Cleveland 206.82.109.0/24",
        "MidWest-IX - Indy",
        "MidWest-IX - OHIO",
        "MidWest-IX - St. Louis",
        "MINAP Milan",
        "MISPA-IXP",
        "MIX MNDC",
        "MIX-BT",
        "MIX-IT",
        "MIX-IT Palermo",
        "MIXP",
        "MIXP.me",
        "MKE-IX",
        "MLIX",
        "MLIXP",
        "MMIX",
        "MoeIX CSX",
        "MoeIX Hong Kong",
        "MoeIX Osaka",
        "MoeIX San Francisco",
        "MoeIX Zhengzhou",
        "MonctonIX",
        "MOZIX",
        "MS-IX",
        "MSK-IX Ekaterinburg",
        "MSK-IX Kazan",
        "MSK-IX Moscow",
        "MSK-IX Novosibirsk",
        "MSK-IX Riga",
        "MSK-IX Rostov-on-Don",
        "MSK-IX Saint-Petersburg",
        "MSK-IX Samara",
        "MSK-IX Stavropol",
        "MSK-IX Vladivostok",
        "MUS-IX",
        "MVIX",
        "MyIX",
        "MYNAP",
        "Múli IXP",
        "N-IX",
        "Namex Rome IXP",
        "NAP Colombia",
        "Nap Peru",
        "NAP.EC - GYE",
        "NAP.EC - UIO",
        "NAPAfrica IX Cape Town",
        "NAPAfrica IX Durban",
        "NAPAfrica IX Johannesburg",
        "NAPAfrica MAPS Johannesburg",
        "NASA-AIX",
        "NashIX",
        "Navégalo IXPCR",
        "NCJX-Neutral Connect J-Xchange",
        "NCL-IX",
        "NDIX",
        "NEO-IX",
        "NetIX",
        "Netnod Copenhagen BLUE -- MTU9K",
        "Netnod Copenhagen GREEN -- MTU9K",
        "Netnod Gothenburg -- MTU1500",
        "Netnod Gothenburg -- MTU4470",
        "Netnod Lulea -- MTU9K",
        "Netnod Stockholm BLUE -- MTU1500",
        "Netnod Stockholm BLUE -- MTU4470",
        "Netnod Stockholm GREEN -- MTU1500",
        "Netnod Stockholm GREEN -- MTU4470",
        "Netnod Sundsvall -- MTU1500",
        "Netnod Sundsvall -- MTU4470",
        "NIX-BD",
        "NIX.CZ",
        "NIX.CZ FENIX",
        "NIX.SK",
        "NIX1",
        "NIX2",
        "NIXA",
        "NIXI Ahmedabad",
        "NIXI Bangalore",
        "NIXI Chennai",
        "NIXI Delhi",
        "NIXI Guwahati",
        "NIXI Hyderabad",
        "NIXI Kolkata",
        "NIXI Mumbai",
        "NIXVAL-ix",
        "NL-ix",
        "NL-ix 2",
        "NNENIX",
        "NNIX",
        "NNOV-IX",
        "NormandIX",
        "Norrnod",
        "NOVO NIX",
        "npIX JWL",
        "npIX PTS",
        "NSPIXP3",
        "NWAX",
        "NYCX",
        "NYIIX",
        "NYIIX Los Angeles",
        "NYIIX Philadelphia",
        "OceanIX",
        "OCIX",
        "OCIX Duesseldorf",
        "OCIX Frankfurt",
        "OCIX-APAC",
        "OCIX-NSW",
        "OCIX-NZ",
        "OCIX-QLD",
        "OCIX-SA",
        "OCIX-VIC",
        "OCIX-WA",
        "OGIX",
        "Ohio IX",
        "OhioIX",
        "OmahaIX",
        "OMSK-IX",
        "OpenIXP / NiCE",
        "OpenIXP / NiCE ixlan1962",
        "OpenSwitch-IX",
        "Oregon Internet Exchange (OIX)",
        "Ouest.Network",
        "OuestIX",
        "Pacific Wave",
        "PacificIX",
        "PacificWave",
        "PaducahIX",
        "PCIX",
        "PCTAIX",
        "Peering.cz",
        "PERM-IX",
        "Peru IX (PIT Peru sac) - Arequipa",
        "Perú IX (PIT Peru sac) - Arequipa",
        "Perú IX (PIT Peru sac) - Lima",
        "PhillyIX",
        "Phoenix IX",
        "Phoenix IX Jumbo",
        "Phoenix-IX",
        "PhOpenIX-Manila",
        "PIONIER-IX",
        "PIPE Networks Adelaide",
        "PIPE Networks Brisbane",
        "PIPE Networks Canberra",
        "PIPE Networks Hobart",
        "PIPE Networks Melbourne",
        "PIPE Networks Sydney",
        "PIRIX",
        "PIT Arica - PIT Chile",
        "PIT Bolivia",
        "PIT Colombia - Bogota",
        "PIT Colombia - Cali",
        "PIT Colombia - Medellin",
        "PIT Concepcion - PIT Chile",
        "PIT entel",
        "PIT MX",
        "PIT Osorno - PIT Chile",
        "PIT Peru",
        "PIT Peru SAC - Lima",
        "PIT Santiago - PIT Chile",
        "PIT Temuco - PIT Chile",
        "PIT-IX",
        "PITER-IX Helsinki",
        "PITER-IX Kiev",
        "PITER-IX Moscow",
        "PITER-IX Riga",
        "PITER-IX St. Petersburg",
        "PITER-IX Tallinn",
        "PIX Montreal",
        "PIX Palestine",
        "PIX Toronto",
        "PIX Vancouver",
        "PKIX",
        "PNG Neutral Internet Exchange",
        "PNG Neutral IX Lae",
        "PNG Neutral IX POM",
        "PNIX",
        "PouIX",
        "POZIX",
        "PS-IX Ramallah",
        "Puerto Rico Internet Exchange",
        "PyramIX",
        "QCIX",
        "QIX",
        "QIX Qatar",
        "R_iX",
        "Raleigh-IX",
        "RED-IX",
        "REDIX Chennai",
        "Remki Internet Exchange (RIX)",
        "REUNIX",
        "Rheintal IX",
        "Richmond-IX",
        "Richmond-IX MTU-9000",
        "RINEX",
        "RIX",
        "RIX (RYUKYUIX)",
        "RO-CIX",
        "RO-IX",
        "RO-IX Frankfurt",
        "RO-IX Stuttgart",
        "RomandIX",
        "RoNIX",
        "ROPN-IX",
        "RTIX",
        "Ruhr-CIX",
        "RVA-IX",
        "RZ-IX",
        "Sacramento-IX",
        "SAIX",
        "SAIX Saudi Arabia",
        "SAT-IX (San Antonio)",
        "SBIX",
        "SBIX DUS",
        "SBIX FRE",
        "SBIX TUU",
        "SCL-IX Chile",
        "SD-NAP",
        "Sea-IX",
        "SEECIX",
        "SerinIX IX",
        "SFINX",
        "SFIX",
        "SFMIX",
        "SFO-IX",
        "SGIX",
        "SH-IX",
        "Sibir-IX",
        "SIEX",
        "SISPA",
        "SIX Seattle",
        "SIX Seattle (Jumbo)",
        "SIX SI",
        "SIX Stavanger",
        "SIX.SK",
        "SIX.SK Košice",
        "SIXP Gambia",
        "SIXP Sudan",
        "SJIX",
        "SLIX - Salt Lake City Internet Exchange",
        "SMILE-IXP",
        "SNAP",
        "SNS-IX",
        "SoIXP",
        "SOLIX",
        "SOX",
        "SOX Serbia",
        "SP-iXP Mumbai",
        "SPACE-IX",
        "Speed-IX",
        "SpokaneIX",
        "SpringIX",
        "SR-IX",
        "STACIX",
        "STAR",
        "STHIX - Copenhagen",
        "STHIX - Gothenburg",
        "STHIX - Stockholm",
        "STHIX - Sundsvall",
        "STHIX - Umeå",
        "STIX",
        "STLIX",
        "STUIX",
        "Stuttgart-IX",
        "SudIX",
        "SUMMIT NIX",
        "SUPRnet",
        "SwissIX",
        "SXMIX",
        "SYMC-IX Bangkok",
        "SYMC-IX BKK1",
        "T-CIX",
        "TahoeIX",
        "TaipeiGigaPoP",
        "TampaIX",
        "TC-IX",
        "Tehran IX",
        "TGIX",
        "TH-IX",
        "Thailand IX (TH-IX)",
        "THESS-IX",
        "Thinx",
        "THINX Warsaw",
        "Tirol-IX",
        "TIX",
        "TIX Tanzania - Arusha (AIXP)",
        "TIX Tanzania - Dar es Salaam",
        "TIX Tanzania - Dodoma",
        "TIX Tanzania - Mbeya",
        "TIX Tanzania - Mwanza",
        "TIX Tanzania - Zanzibar",
        "TIX Tromsø",
        "TLLIX",
        "TN-IX",
        "TOHU IX",
        "TOP-IX",
        "TorIX",
        "TOUIX",
        "TP-IX",
        "TPAIX",
        "TPIX Warsaw",
        "TPIX-TW",
        "TR-IX",
        "TransIX Network",
        "TRDIX Trondheim",
        "TREX",
        "TRUE-IX",
        "TTIX",
        "TTIX2",
        "TunIXP",
        "Turk-IX",
        "TWIX",
        "UA-IX",
        "UAE-IX",
        "UEPG Internet Exchange",
        "UIXP",
        "ULN-IX",
        "United Peering - UP-IXP",
        "UNM-Exch Canada-West",
        "UNY-IX",
        "VANIX",
        "VarnaIX",
        "Vegas-IX",
        "Vegas-IX MTU-9000",
        "VIX",
        "VIX.VU",
        "VIXP",
        "VNIX-DN",
        "VNIX-HCM",
        "VNIX-HN",
        "VSIX",
        "W-IX",
        "WAF-IX, Lagos",
        "Waterloo Internet Exchange",
        "Willamette Internet Exchange",
        "WIX",
        "WIX-NZ",
        "WPGIX",
        "WRIX",
        "YAR-IX",
        "YEGIX",
        "YJSNPIX",
        "YRIX",
        "YXEIX",
        "YYCIX",
        "ZXIX Hangzhou (Y)",
        "ZXIX Hong Kong (Y)"
    ];

    static PROPERTY_FILTER_STRINGS = {
        filteringAriaLabel: "your choice",
        dismissAriaLabel: "Dismiss",
        filteringPlaceholder: "Search",
        groupValuesText: "Values",
        groupPropertiesText: "Properties",
        operatorsText: "Operators",
        operationAndText: "and",
        operationOrText: "or",
        operatorLessText: "Less than",
        operatorLessOrEqualText: "Less than or equal",
        operatorGreaterText: "Greater than",
        operatorGreaterOrEqualText:
        "Greater than or equal",
        operatorContainsText: "Contains",
        operatorDoesNotContainText: "Does not contain",
        operatorEqualsText: "Equals",
        operatorDoesNotEqualText: "Does not equal",
        editTokenHeader: "Edit filter",
        propertyText: "Property",
        operatorText: "Operator",
        valueText: "Value",
        cancelActionText: "Cancel",
        applyActionText: "Apply",
        allPropertiesLabel: "All properties",
        tokenLimitShowMore: "Show more",
        tokenLimitShowFewer: "Show fewer",
        clearFiltersText: "Clear filters",
        removeTokenButtonAriaLabel: token =>
            `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
        enteredTextLabel: text => `Use: "${text}"`
    }

    static CONFIRM_DELIVERY_DATE_MODAL = {
        header: "Past date selected!",
        message: "Date entered is in the past, confirm date before Submission.",
        confirm: "Confirm"
    }

    static STAGES = {
        local: "local",
        test: "test",
        gamma: "gamma",
        prod: "prod"
    }

    static DOMAINS = {
        fremont: "fremont",
        lighthouse: "lighthouse"
    }

    static CLOUDWATCH_RUM_CONFIG = {
        // Details about the RUM config https://github.com/aws-observability/aws-rum-web/blob/main/docs/configuration.md
        [Constants.STAGES.local]: {
            // TODO Change this values as per the instructions below to make CW RUM work in Local Stack
            // We need to deploy the FremontMonitoringStack using FremontPipelineCDK
            // We then need to copy the values in the CW RUM config values to here
            allowCookies: true,
            applicationRegion: "us-west-2",
            applicationVersion: "1.0.0",
            enableXRay: true,
            endpoint: "https://dataplane.rum.us-west-2.amazonaws.com",
            lighthouseApplicationId: "28ff7439-b1a1-4b14-9759-8f89871f58e6",
            lighthouseIdentityPoolId: "us-west-2:9cbbcec1-5479-4145-b894-e92b76a2486e",
            lighthouseGuestRoleArn:
                "arn:aws:iam::851725637020:role/FremontMonitoringStack-85-LighthouseCloudwatchRUMAp-3xWrOiZPzq9X",
            sessionSampleRate: 1,
            sessionEventLimit: 0,
            telemetries: ["errors", "performance", "http"]
        },
        [Constants.STAGES.test]: {
            allowCookies: true,
            applicationRegion: "us-west-2",
            applicationVersion: "1.0.0",
            enableXRay: true,
            endpoint: "https://dataplane.rum.us-west-2.amazonaws.com",
            lighthouseApplicationId: "bd6c682e-78a6-476c-9fe2-8407b682c5bb",
            lighthouseIdentityPoolId: "us-west-2:d9ee6bd2-4b12-40a4-b60f-d716fa34f754",
            lighthouseGuestRoleArn:
                "arn:aws:iam::822949855504:role/FremontMonitoringStack-82-LighthouseCloudwatchRUMAp-MPOxYDlaeUO4",
            sessionSampleRate: 1,
            sessionEventLimit: 0,
            telemetries: ["errors", "performance", "http"]
        },
        [Constants.STAGES.gamma]: {
            allowCookies: true,
            applicationRegion: "us-west-2",
            applicationVersion: "1.0.0",
            enableXRay: true,
            endpoint: "https://dataplane.rum.us-west-2.amazonaws.com",
            lighthouseApplicationId: "a0a1bc31-fb96-4bc7-b5b9-ee172129a6b0",
            lighthouseIdentityPoolId: "us-west-2:4e815eac-4989-43f4-8124-8c82c6de1606",
            lighthouseGuestRoleArn:
                "arn:aws:iam::399193497724:role/FremontMonitoringStack-39-LighthouseCloudwatchRUMAp-ZRAu4jKQFy8n",
            sessionSampleRate: 1,
            sessionEventLimit: 0,
            telemetries: ["errors", "performance", "http"]
        },
        [Constants.STAGES.prod]: {
            allowCookies: true,
            applicationRegion: "us-east-1",
            applicationVersion: "1.0.0",
            enableXRay: true,
            endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
            lighthouseApplicationId: "54b38796-5677-43df-ac0a-cb3010286ff9",
            lighthouseIdentityPoolId: "us-east-1:752de64b-aa36-46ca-a9f5-7e3832ed1403",
            lighthouseGuestRoleArn:
                "arn:aws:iam::211980640469:role/FremontMonitoringStack-21-LighthouseCloudwatchRUMAp-ITIemFySzSga",
            sessionSampleRate: 1,
            sessionEventLimit: 0,
            telemetries: ["errors", "performance", "http"]
        }
    }

    static OPTICAL_BACKBONE_ORDER_META_STRING_ATTRIBUTES = [
        Constants.ATTRIBUTES.actualFiberDeliveryDate,
        Constants.ATTRIBUTES.atpFile,
        Constants.ATTRIBUTES.decommissioningMcm,
        Constants.ATTRIBUTES.estimatedFiberDeliveryDate,
        Constants.ATTRIBUTES.fiberHandoverMcm,
        Constants.ATTRIBUTES.ilaSiteSurveyCompletionDate,
        Constants.ATTRIBUTES.lineCutsheetMcm,
        Constants.ATTRIBUTES.mgmtCutsheetMcm,
        Constants.ATTRIBUTES.mutualTestCompletionDate,
        Constants.ATTRIBUTES.opsChecklistMcm,
        Constants.ATTRIBUTES.opticalClientCutsheetMcm,
        Constants.ATTRIBUTES.rackCutsheetMcm,
        Constants.ATTRIBUTES.systemCommissioningCompletionDate,
        Constants.ATTRIBUTES.systemHandoverMcm,
        Constants.ATTRIBUTES.terminalFOCCompletionDate
    ]

    static OPTICAL_BACKBONE_ORDER_META_BOOLEAN_ATTRIBUTES = [
        Constants.ATTRIBUTES.isDecommissioningMcmExecuted,
        Constants.ATTRIBUTES.isFiberDelivered,
        Constants.ATTRIBUTES.isHardwareDelivered,
        Constants.ATTRIBUTES.isILASiteSurveyComplete,
        Constants.ATTRIBUTES.isMutualTestComplete,
        Constants.ATTRIBUTES.isOpticalClientCutsheetSharedForCabling,
        Constants.ATTRIBUTES.isSystemCommissioningComplete,
        Constants.ATTRIBUTES.isTerminalFOCComplete
    ]

    static IGNORED_CIRCUIT_DESIGN_LIFECYCLES = [
        Constants.LIFECYCLE_STAGES.cancelled,
        Constants.LIFECYCLE_STAGES.decommissioned,
        Constants.LIFECYCLE_STAGES.deprecated,
        Constants.LIFECYCLE_STAGES.pendingDecommission
    ]
}
